import styles from "./style.module.css";
import React from "react";

//https://svg2jsx.com/

export function HomeIcon() {
	return (
		<svg
			fill="#000000"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width="24px"
			height="24px"
		>
			<path d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 11 21 L 11 15 L 13 15 L 13 21 L 20 21 L 20 12 L 23 12 L 12 2.0996094 z M 12 4.7910156 L 18 10.191406 L 18 11 L 18 19 L 15 19 L 15 13 L 9 13 L 9 19 L 6 19 L 6 10.191406 L 12 4.7910156 z" />
		</svg>
	);
}

export function TrashIcon() {
	return (
		<svg
			className={styles.TrashIcon}
			width="19"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19 7L18.1327 19.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224C6.81296 21 5.94208 20.1891 5.86732 19.1425L5 7M10 11V17M14 11V17M15 7V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V7M4 7H20"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			></path>
		</svg>
	);
}

export function AddIcon() {
	return (
		<svg
			className={styles.AddIcon}
			width="30"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
		>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
		</svg>
	);
}

function CheckSVG() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="11" height="9">
			<path
				className={styles.CheckIcon}
				// fill="none"
				stroke="#FFF"
				strokeWidth="2"
				d="M1 4.304L3.696 7l6-6"
			/>
		</svg>
	);
}

export function CheckCircle() {
	return <div className={styles.checkCircle}></div>;
}

export function CheckCircleComplete() {
	return (
		<div className={`${styles.checkCircle} ${styles.completed}`}>
			<CheckSVG />
		</div>
	);
}

export function ShareIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			width="20px"
			height="20px"
			viewBox="0 0 60 60"
		>
			<path d="M30 0C13.458 0 0 13.458 0 30s13.458 30 30 30 30-13.458 30-30S46.542 0 30 0zm-8 29a5.97 5.97 0 01-1.448 3.898l12.716 9.325A6.013 6.013 0 0139 38c3.309 0 6 2.691 6 6s-2.691 6-6 6c-3.131 0-5.705-2.411-5.973-5.474L18.961 34.212A5.957 5.957 0 0116 35c-3.309 0-6-2.691-6-6s2.691-6 6-6c1.077 0 2.086.289 2.961.788l14.065-10.314C33.295 10.411 35.869 8 39 8c3.309 0 6 2.691 6 6s-2.691 6-6 6a6.01 6.01 0 01-5.731-4.223l-12.716 9.325A5.975 5.975 0 0122 29z"></path>
		</svg>
	);
}

export function LinkIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			width="20px"
			height="20px"
			viewBox="0 0 60 60"
		>
			<path d="M51.173 3.801c-5.068-5.068-13.315-5.066-18.384 0l-9.192 9.192a2 2 0 102.828 2.828l9.192-9.192a8.938 8.938 0 016.363-2.622c2.413 0 4.673.932 6.364 2.623s2.623 3.951 2.623 6.364a8.934 8.934 0 01-2.623 6.363L36.325 31.379c-3.51 3.508-9.219 3.508-12.729 0a2 2 0 10-2.828 2.828c2.534 2.534 5.863 3.801 9.192 3.801s6.658-1.267 9.192-3.801l12.021-12.021c2.447-2.446 3.795-5.711 3.795-9.192 0-3.482-1.348-6.746-3.795-9.193z"></path>
			<path d="M27.132 40.57l-7.778 7.778a8.935 8.935 0 01-6.364 2.623 8.937 8.937 0 01-6.364-2.623c-3.509-3.509-3.509-9.219 0-12.728L17.94 24.306a8.938 8.938 0 016.364-2.622c2.412 0 4.672.932 6.363 2.622a2 2 0 102.828-2.828c-5.067-5.067-13.314-5.068-18.384 0L3.797 32.793C1.351 35.239.003 38.504.003 41.985c0 3.48 1.348 6.745 3.795 9.191a12.905 12.905 0 009.191 3.795c3.481 0 6.746-1.348 9.192-3.795l7.778-7.778a2 2 0 00-2.827-2.828z"></path>
		</svg>
	);
}
export function UnLinkIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20px"
			height="20px"
			viewBox="0 0 24 24"
		>
			<path d="M11.853 8.842a.5.5 0 11-.706-.709l2.175-2.162a3.334 3.334 0 014.704.003 3.309 3.309 0 01.008 4.679l-2.216 2.203a.5.5 0 01-.705-.709l2.205-2.193a2.31 2.31 0 00.002-3.272 2.334 2.334 0 00-3.293-.002l-2.174 2.162zm.294 6.304a.5.5 0 11.706.708l-2.174 2.171a3.327 3.327 0 11-4.707-4.708l2.175-2.17a.5.5 0 01.706.707L6.68 14.025a2.327 2.327 0 00-.002 3.29 2.33 2.33 0 003.296.003l2.174-2.172zM10 6.5a.5.5 0 01-1 0v-2a.5.5 0 011 0v2zm5 13a.5.5 0 11-1 0v-2a.5.5 0 111 0v2zM7.854 7.146a.5.5 0 11-.708.708l-2-2a.5.5 0 11.708-.708l2 2zm11 11a.5.5 0 01-.708.708l-2-2a.5.5 0 01.708-.708l2 2zM6.5 9a.5.5 0 010 1h-2a.5.5 0 010-1h2zm13 5a.5.5 0 110 1h-2a.5.5 0 110-1h2z"></path>
		</svg>
	);
}
