import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AddIcon } from "../../Icons/Icons";
import { SEGMENT_PATH_LANDING } from "../../../constants/paths";

function Breadcrumb({
	currentBoardName,
	onBoardNameChange,
	onAddColumnRequest,
}) {
	return (
		<div className={styles.breadCrumbLayout}>
			<span className={styles.textBreadcrumb}>
				<Link to={SEGMENT_PATH_LANDING} className={styles.breadcrumbTextHome}>
					Boards
				</Link>

				<span>/</span>
				<input
					type="text"
					defaultValue={currentBoardName}
					className={`${styles.breadcrumbTextCurrent} ${styles.truncate}`}
					onChange={onBoardNameChange}
				/>
			</span>
			<AddGroupForm AddGroupCommand={onAddColumnRequest} />
		</div>
	);
}

function AddGroupForm({ AddGroupCommand }) {
	const refInput = useRef(null);

	const [inputFieldValue, setInputField] = useState("");

	function OnInputTextChange(e) {
		setInputField(e.target.value);
	}

	function AddGroupWrapper(_name) {
		if (inputFieldValue.length <= 1) {
			refInput?.current?.focus();
			return;
		}

		AddGroupCommand(inputFieldValue);
		setInputField("");
		refInput?.current?.blur();
	}

	function onAddButtonClick(e) {
		e.preventDefault();
		AddGroupWrapper(inputFieldValue);
	}

	function onFormSubmit(e) {
		e.preventDefault();
		AddGroupWrapper(inputFieldValue);
	}

	return (
		<div>
			<form
				className={styles.addColumnGroup}
				onSubmit={onFormSubmit}
				autoComplete="off"
			>
				<div onClick={onAddButtonClick}>
					<AddIcon />
				</div>
				<input
					ref={refInput}
					type="text"
					name="input_first_name"
					onChange={OnInputTextChange}
					placeholder="Add a new Group"
					value={inputFieldValue}
					maxLength={20}
					className={styles.addColumnTextField}
				/>
			</form>
		</div>
	);
}

Breadcrumb.propTypes = {
	currentBoardName: PropTypes.string,
	onBoardNameChange: PropTypes.func,
	onAddColumnRequest: PropTypes.func,
};

export default Breadcrumb;
