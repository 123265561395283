import { get, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { db } from "../components/Firebase/fbConfig.js";
import {
	enumInviteProcessState,
	enumInviteState,
} from "../constants/inviteState.js";
import { useAuth } from "../context/AuthContext.js";
import { SEGMENT_PATH_LANDING } from "../constants/paths.js";
import useBoardList, { UserBoardStateEnum } from "./useBoardList.js";

function InviteError(_state = enumInviteState.unknownError, message) {
	this.InviteState = _state;
	this.message = message;
	this.name = "InviteError";
}

// Make the exception convert to a pretty string when used as a string
// (e.g., by the error console)
InviteError.prototype.toString = function () {
	return `${this.name}: "${this.message}"`;
};

function PromiseDelay(timeToDelay) {
	return new Promise((resolve) => setTimeout(resolve, timeToDelay));
}

function objFromEnum(_enumInviteState) {
	const newObj = {
		..._enumInviteState,
		stateEnum: _enumInviteState, //enumInviteState.start
		key: `${_enumInviteState.name}`,
		loading: enumInviteProcessState.init,
	};
	return newObj;
}

const useTemp = (_inviteActions) => {
	var initArray = [];

	_inviteActions.forEach((element) => {
		const newObj = objFromEnum(element);
		initArray.push(newObj);
	});

	const {
		AuthAPI_LoginAnon: _funcLoginAnon,
		AuthAPI_CurrentUser: _currentUser,
	} = useAuth();

	let _userId = _currentUser?.uid;
	const { LinkBoard: _linkBoard } = useBoardList(_userId);

	const [stateList, setStateList] = useState(initArray);

	let navigate = useNavigate();
	let [searchParams] = useSearchParams();

	useEffect(() => {
		let didCancel = false;

		async function delayedStateChange() {
			if (stateList.length <= 0) return;
			if (didCancel) return;
			var newStateList = Array.from(stateList);

			const currentState = newStateList[0];

			//guarantee execution completion!
			//consume the current state to
			//pop the current state off the front of stack

			newStateList.shift();

			// console.log("Current State: " + currentState.name);

			//do async work which may alter the stack
			//default delay before transition
			await PromiseDelay(1000);

			switch (currentState.stateEnum) {
				case enumInviteState.start:
					break;

				case enumInviteState.loginCheck:
					if (_currentUser) {
						newStateList.unshift(objFromEnum(enumInviteState.loginComplete));
					} else {
						newStateList.unshift(objFromEnum(enumInviteState.loginAnon));
					}
					break;

				case enumInviteState.loginAnon:
					newStateList.unshift(objFromEnum(enumInviteState.loginProgress));
					_funcLoginAnon().catch(() => {
						newStateList.unshift(objFromEnum(enumInviteState.AnonLoginError));
					});
					break;

				case enumInviteState.loginProgress:
					if (_currentUser) {
						newStateList.unshift(objFromEnum(enumInviteState.loginComplete));
					}
					break;

				case enumInviteState.inviteCheck:
					var board_id = searchParams.get("boardId");
					let foreign_user_id = searchParams.get("foreignId");
					var targetBoard = await GetUserBoardData(foreign_user_id, board_id);

					// @ts-ignore
					var targetBoardLinkState = targetBoard?.linkState;

					var inviteValid = UserBoardStateEnum.linked !== targetBoardLinkState;
					if (inviteValid) {
						newStateList.unshift(objFromEnum(enumInviteState.inviteValid));
					} else {
						newStateList.unshift(objFromEnum(enumInviteState.boardTakenError));
					}
					break;

				case enumInviteState.inviteValid:
					let inviteParam = searchParams.get("boardId");
					let foreign_id = searchParams.get("foreignId");

					let myUserId = _currentUser?.uid;

					await _linkBoard(myUserId, inviteParam, foreign_id)
						.then(() => {
							newStateList.unshift(objFromEnum(enumInviteState.linked));
						})
						.catch((error) => {
							console.error(error);
							newStateList.unshift(objFromEnum(enumInviteState.unknownError));
						});
					break;

				case enumInviteState.linked:
					await PromiseDelay(1000);
					break;

				case enumInviteState.navHome:
					await PromiseDelay(2000);
					navigate(SEGMENT_PATH_LANDING, { replace: true });

					return;

				case enumInviteState.unknownError:
				case enumInviteState.AnonLoginError:
				case enumInviteState.boardTakenError:
					return;

				default:
					break;
			}

			//prevent hook from updating this components state when unmounted
			//(async problems prevented by cleanup function below)
			if (didCancel) return;

			newStateList[0] = {
				...newStateList[0],
				loading: enumInviteProcessState.working,
			};

			//force the state to change
			setStateList([...newStateList]);
		}

		delayedStateChange().catch(function (error) {
			if (error instanceof InviteError) {
				console.error(`InviteError: ${error}`);
			}
			console.error(`State Transition Error: ${error}`);
		});

		return () => {
			// Remember if we start fetching something else
			didCancel = true;
		};
	}, [
		stateList,
		navigate,
		searchParams,
		_funcLoginAnon,
		_currentUser,
		_linkBoard,
	]);

	return [stateList, setStateList];
};

export default useTemp;

async function GetUserBoardData(_userId, _boardKey) {
	//await PromiseDelay(2000);

	if (!_userId)
		return Promise.reject("_userId is invalid: _userId = " + _userId);

	if (!_boardKey)
		return Promise.reject("_boardKey is invalid: _boardKey = " + _boardKey);

	const userBoardRefString = `/user-boards/${_userId}/${_boardKey}`;
	const BoardRef = ref(db, userBoardRefString);

	//cache the current value before we delete
	var userBoardData = null;
	await get(BoardRef)
		.then((snapshot) => {
			if (snapshot.exists()) {
				userBoardData = snapshot.val();
			} else {
				console.warn("No data available");
			}
		})
		.catch((error) => {
			console.error(error);
		});

	if (!userBoardData) {
		let message = `useBoardInvite/GetUserBoardData failed: No value to delete at ${userBoardRefString} `;
		console.warn(message);
		return Promise.reject(message);
	}

	return userBoardData;
}

//use the google api to generate a short deep link
export async function generateShortDeepLink() {
	//check if we're on prod
	const hostName = window.location.hostname;
	if (hostName === "localhost" || hostName === "127.0.0.1") {
		return {
			shortLink:
				"http://localhost:3000/invite/board?boardId=-MqomfB1HAwSiEy_G8od",
		};
	}

	const dynamicLinkInfoPayload = {
		domainUriPrefix: "https://top-ten.app/link",
		link: "https://top-ten.app/board/-Mq0P9r35TR_8gIMKO2H?user=chaance&thing=me",
	};

	const suffixPayload = {
		option: "SHORT",
	};

	const bodyPayload = JSON.stringify({
		dynamicLinkInfo: dynamicLinkInfoPayload,
		suffix: suffixPayload,
	});

	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: bodyPayload,
	};

	const shortenDeepLinkPost =
		"https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" +
		process.env.REACT_APP_API_KEY;

	const response = await fetch(shortenDeepLinkPost, requestOptions);

	if (!response.ok) {
		const message = `An error has occured: ${response.status}`;
		throw new Error(message);
	}

	return await response.json();
}
