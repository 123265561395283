import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/messaging";

const fbconfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const firebase = initializeApp(fbconfig);

// Get a reference to the database service
const db = getDatabase(firebase);

export default firebase;

export { firebase, db };
