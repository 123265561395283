import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.css";

export const AnonHeader = ({ user, onLogin, onLogout, onCreateAccount }) => (
	<header>
		<div className={styles.flexParent}>
			<div className={styles.flexChildLeft}>
				<img width="32" height="32" src="/logo512.png" alt="Top Ten Logo" />
				<h1>Top Ten</h1>
			</div>
			<div className={styles.flexChildRight}>
				{user ? (
					<button onClick={onLogout} id="logOut-button">
						Sign Out
					</button>
				) : (
					<>
						<button onClick={onLogin} id="logOut-button">
							Log in
						</button>
						<button onClick={onCreateAccount} id="logOut-button">
							Sign up
						</button>
					</>
				)}
			</div>
		</div>
	</header>
);

AnonHeader.propTypes = {
	user: PropTypes.shape({}),
	onLogin: PropTypes.func.isRequired,
	onLogout: PropTypes.func.isRequired,
	onCreateAccount: PropTypes.func.isRequired,
};

AnonHeader.defaultProps = {
	user: null,
};
