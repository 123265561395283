import * as React from "react";
import styles from "./style.module.css";
import { ReactComponent as FaceIcon } from "../../assets/face_retouching_natural_rounded_24px.svg";

function BoardListAvatar({ onNavClick, onFaceClick, seed }) {
	const imageUrl = `https://avatars.dicebear.com/api/open-peeps/:${seed}.svg`;

	var sectionStyle = {
		backgroundImage: `url(${imageUrl})`,
	};

	return (
		<>
			<FaceIcon className={styles.SvgIcon} onClick={onFaceClick} />
			<div
				onClick={onNavClick}
				style={sectionStyle}
				className={styles.avatar_root_default}
			></div>
		</>
	);
}

export default BoardListAvatar;
