import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { db } from "../components/Firebase/fbConfig.js";

//https://console.firebase.google.com/project/top-ten-d9fc1/database/top-ten-d9fc1-default-rtdb/data

const useFollowers = (userId) => {
	const [FollowerData, SetFollowerData] = useState(null);

	// @ts-ignore
	useEffect(() => {
		const tasksTableRefString = `/followers/${userId}`;
		const tasksTableRef = ref(db, tasksTableRefString);

		return onValue(tasksTableRef, (snap) => {
			if (snap !== undefined) {
				SetFollowerData(snap.val());
			}
		});
	}, [userId]);

	return FollowerData;
};

export default useFollowers;
