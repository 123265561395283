export const enumInviteState = Object.freeze({
	// todo: separate message data
	start: { name: "start", title: "", message: "Starting..." },
	loginCheck: {
		name: "loginCheck",
		title: "Login",
		message: "Checking Authorization",
	},
	loginProgress: {
		name: "loginProgress",
		title: "Login Progress",
		message: "Logging In Anonymously...",
	},
	loginAnon: {
		name: "loginAnon",
		title: "Login Progress",
		message: "Logging In Anonymously...",
	},
	loginComplete: {
		name: "loginComplete",
		title: "Login Progress",
		message: "Login Complete!",
	},
	inviteCheck: {
		name: "inviteCheck",
		title: "Checking Invite",
		message: "Checking for valid Top Ten Invitation...",
	},
	inviteValid: {
		name: "inviteValid",
		title: "Is Invite Valid?",
		message: "Valid Invite found! Linking...",
	},
	linked: {
		name: "linked",
		title: "Linking Status",
		message: "Board link request success!",
	},
	navHome: {
		name: "navHome",
		title: "Take you to the App!",
		message: "Taking you to your Top Ten boards!",
	},
	unknownError: {
		name: "unknownError",
		title: "Error",
		message: "unknownError",
	},
	boardTakenError: {
		name: "boardTakenError",
		title: "Invite Error",
		message: "This board is already linked 😞",
	},
	AnonLoginError: {
		name: "AnonLoginError",
		title: "Login Error",
		message: "We could not log you in - try again later!",
	},
});

export const enumInviteProcessState = Object.freeze({
	// todo: separate message data
	init: { name: "init", message: "Initializing..." },
	working: { name: "working", message: "Working..." },
	finshed: { name: "finshed", message: "Finishing..." },
	error: { name: "error", message: "error..." },
});
