import React, { useRef, useState, useEffect, useImperativeHandle } from "react";
import styles from "./style.module.css";

function NameEditFieldImp(
	{ textClassName, inputClassName, text: textParam, onNameChange },
	forwardRef
) {
	//https://dmitripavlutin.com/controlled-inputs-using-react-hooks/
	//https://kentcdodds.com/blog/understanding-reacts-key-prop

	const [editingCol, setEditing] = useState(false);
	const [currentText, setCurrentText] = useState(textParam);

	useImperativeHandle(forwardRef, () => ({
		focus: () => {
			setEditing(true);
			setTimeout(() => {
				//needs delay as the thing we clicked on is actually the header
				refInput?.current?.focus();
			}, 50);
		},
	}));

	//make sure we update our text if the param changes
	useEffect(() => {
		setCurrentText(textParam);
	}, [textParam]);

	const refInput = useRef(null);

	function onChange(event) {
		setCurrentText(event.target.value);
	}

	function onLoseFocus(event) {
		event.preventDefault();
		setEditing(false);
		onNameChange(currentText);
	}

	function EditModeOn(event) {
		event.preventDefault();
		setEditing(true);
		setTimeout(() => {
			//needs delay as the thing we clicked on is actually the header
			refInput?.current?.focus();
		}, 50);
	}

	function handleKeyPress(event) {
		if (event.key === "Enter") {
			refInput?.current?.blur();
		}
	}

	function handleKeyDown(event) {
		if (event.key === "Escape") {
			refInput?.current?.blur();
		}
	}

	return (
		<>
			<input
				ref={refInput}
				value={currentText}
				onChange={onChange}
				onBlur={onLoseFocus}
				onKeyPress={handleKeyPress}
				onKeyDown={handleKeyDown}
				className={`${inputClassName} ${editingCol ? "" : styles.hidden}`}
			/>
			<div
				className={`${textClassName} ${editingCol ? styles.hidden : ""}`}
				onClick={EditModeOn}
			>
				{currentText}
			</div>
		</>
	);
}

const NameEditField = React.forwardRef(NameEditFieldImp);

export default NameEditField;
