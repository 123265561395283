import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { enumInviteProcessState } from "../../../constants/inviteState";
import useTemp from "../../../hooks/useInviteSwitchboard";
import { AnonHeader } from "../../AnonHeader";
import { Button } from "../../Button";
import styles from "./style.module.css";

const CounterContext = React.createContext(undefined);

function DebugInviteProvider({ children, value }) {
	return (
		<CounterContext.Provider value={value}>{children}</CounterContext.Provider>
	);
}

function useCounterContext() {
	const context = React.useContext(CounterContext);
	if (context === undefined) {
		throw new Error("useCounterContext must be used within a CounterProvider");
	}
	return context;
}

function Counter({ children, onChange, initialValue = 0 }) {
	const [count, setCount] = useState(initialValue);

	const firstMounded = useRef(true);
	useEffect(() => {
		if (!firstMounded.current) {
			onChange && onChange(count);
		}
		firstMounded.current = false;
	}, [count, onChange]);

	const handleIncrement = () => {
		setCount(count + 1);
	};

	const handleDecrement = () => {
		setCount(Math.max(0, count - 1));
	};

	return (
		<DebugInviteProvider value={{ count, handleIncrement, handleDecrement }}>
			<div>{children}</div>
		</DebugInviteProvider>
	);
}

function Count({ max }) {
	const { count } = useCounterContext();

	const hasError = max ? count >= max : false;

	return (
		<>
			<div>{count}</div>
			<div>{hasError}</div>
		</>
	);
}

Counter.Count = Count;

Counter.Label = Label;

function Increment({ icon = "plus" }) {
	const { handleIncrement } = useCounterContext();
	return (
		<Button primary={true} label={"Increment"} onClick={handleIncrement} />
	);
}

Counter.Increment = Increment;

function Decrement({ icon = "minus" }) {
	const { handleDecrement } = useCounterContext();

	return (
		<Button primary={true} label={"Decrement"} onClick={handleDecrement} />
	);
}
Counter.Decrement = Decrement;

function Label({ children }) {
	return <div>{children}</div>;
}

const Test = ({ stations }) => {
	function WriteColumnElements(station) {
		const isWorking = station.loading === enumInviteProcessState.working;
		return (
			<div
				key={station.key}
				className={`${styles.flexChild} ${isWorking ? styles.raise : ""}`}
			>
				<div className={`${styles.flexChildContents} ${isWorking ? "" : ""}`}>
					<div>{isWorking ? station.message : station.title}</div>

					<div className={isWorking ? styles.spinner : ""} />
				</div>
				{/* <div>{station.name || ``}</div> */}
			</div>
		);
	}

	return <>{stations.map(WriteColumnElements)}</>;
};

export function Stepper({
	pActions = [],
	user,
	onLogin,
	onLogout,
	onCreateAccount,
}) {
	const [stateArray] = useTemp(pActions);

	const handleChangeCounter = (count) => {
		console.log("counts", count);
	};

	useEffect(() => {
		async function handleKeyUp(event) {
			switch (event.key) {
				case "ArrowDown":
					break;

				case "Control":
					break;

				default:
					break;
			}
		}

		window.addEventListener("keyup", handleKeyUp);
		return () => window.removeEventListener("keyup", handleKeyUp);
	}, []);

	function RenderCounter() {
		const showCounter = false;
		if (!showCounter) return null;
		return (
			<Counter onChange={handleChangeCounter}>
				<Counter.Decrement />
				<Counter.Label>Counter</Counter.Label>
				<Counter.Count max={10} />
				<Counter.Increment />
			</Counter>
		);
	}

	// console.log(JSON.stringify(stateArray[0]));

	const isAnon = user?.email ? false : true;

	return (
		<>
			{isAnon ? (
				<AnonHeader
					user={user}
					onLogin={onLogin}
					onLogout={onLogout}
					onCreateAccount={onCreateAccount}
				/>
			) : (
				<></>
			)}

			<RenderCounter></RenderCounter>

			<div className={styles.flexContainer}>
				<Test stations={stateArray} />
			</div>
		</>
	);
}

Stepper.propTypes = {
	user: PropTypes.shape({}),
	onLogin: PropTypes.func.isRequired,
	onLogout: PropTypes.func.isRequired,
	onCreateAccount: PropTypes.func.isRequired,
};

Stepper.defaultProps = {
	user: null,
};

export default Stepper;
