import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { db } from "../components/Firebase/fbConfig.js";

//https://console.firebase.google.com/project/top-ten-d9fc1/database/top-ten-d9fc1-default-rtdb/data

const useFollowsList = (userId) => {
	const [FollowsData, SetFollowsData] = useState(null);

	// @ts-ignore
	useEffect(() => {
		const tasksTableRefString = `/follows/${userId}`;
		const tasksTableRef = ref(db, tasksTableRefString);

		return onValue(tasksTableRef, (snap) => {
			if (snap !== undefined) {
				SetFollowsData(snap.val());
			}
		});
	}, [userId]);

	return FollowsData;
};

export default useFollowsList;
