import React from "react";
import PropTypes from "prop-types";

export const Button = ({ primary, label, ...props }) => {
	const mode = primary ? "button-primary" : "";
	return (
		<button type="button" className={["button", mode].join(" ")} {...props}>
			{label}
		</button>
	);
};

Button.propTypes = {
	primary: PropTypes.bool,
	onClick: PropTypes.func,
};

Button.defaultProps = {
	primary: false,
	onClick: undefined,
};
