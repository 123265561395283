import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "skeleton-css/css/normalize.css";
// import "skeleton-css/css/skeleton.css";
import PhoneImage from "../../assets/iphone.png";
import { SEGMENT_PATH_LANDING } from "../../constants/paths";
import { useAuth } from "../../context/AuthContext.js";
import assert from "../../utils/assert.js";
import styles from "./style.module.css";

function SignIn() {
	let navigate = useNavigate();
	let location = useLocation();

	let from = location.state?.from?.pathname || SEGMENT_PATH_LANDING;

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	//define {funcLoginAnon, funcLoginEmail} with weird js object destructuring bullshit
	var {
		AuthAPI_LoginAnon: funcLoginAnon,
		AuthAPI_LoginEmail: funcLoginEmail,
		AuthAPI_RegisterWithEmailAndPassword: funcRegisterEmail,
	} = useAuth();

	assert(typeof funcLoginAnon !== "undefined", "funcLoginAnon: Undefined");
	assert(typeof funcLoginEmail !== "undefined", "funcLoginEmail: Undefined");

	function onAttemptSignIn(event) {
		event.preventDefault();

		funcLoginEmail(email, password)
			.then(function () {
				//from example docs:
				//Send them back to the page they tried to visit when they were
				// redirected to the login page. Use { replace: true } so we don't create
				// another entry in the history stack for the login page.  This means that
				// when they get to the protected page and click the back button, they
				// won't end up back on the login page, which is also really nice for the
				// user experience.
				navigate(from, { replace: true });
			})
			.catch((error) => {
				setError(error);
			});
	}

	function onAttemptSignUp(event) {
		event.preventDefault();

		//remember: once auth is complete this SignIn form no longer renders
		funcRegisterEmail("fixme", email, password) //returns Promise
			.then(function () {
				//from example docs:
				//Send them back to the page they tried to visit when they were
				// redirected to the login page. Use { replace: true } so we don't create
				// another entry in the history stack for the login page.  This means that
				// when they get to the protected page and click the back button, they
				// won't end up back on the login page, which is also really nice for the
				// user experience.
				navigate(from, { replace: true });
			})
			.catch((error) => {
				setError(error);
			});
	}

	const loginAnonymously = (event) => {
		event.preventDefault();

		//remember: once auth is complete this SignIn form no longer renders
		funcLoginAnon() //returns Promise
			.then(function () {
				//from example docs:
				//Send them back to the page they tried to visit when they were
				// redirected to the login page. Use { replace: true } so we don't create
				// another entry in the history stack for the login page.  This means that
				// when they get to the protected page and click the back button, they
				// won't end up back on the login page, which is also really nice for the
				// user experience.
				navigate(from, { replace: true });
			})
			.catch((error) => {
				setError(error);
			});
	};

	const isInvalid = password === "" || email === "";

	return (
		<div>
			<div className={`${styles.section} ${styles.hero}`}>
				<div className={styles.container}>
					<div className={styles.halfScreen}>
						<h4 className={styles.heroHeading}>
							Suggest what your friends and family should watch with Top Ten!
						</h4>
						<div className="button button-primary" onClick={loginAnonymously}>
							Try Top Ten!
						</div>
					</div>
					<div className={`${styles.halfScreen} ${styles.phones}`}>
						<img className={styles.phone} src={PhoneImage} alt="Phone" />
						<img className={styles.phone} src={PhoneImage} alt="Phone" />
					</div>
				</div>
			</div>

			<div className={`${styles.section} ${styles.docsSection}`}>
				<div className={styles.container}>
					<h6 className={styles.docsHeader}>Sign In</h6>
					<p>Login to save your recommendations!</p>
					<div>
						<form>
							<input
								className={styles.fullWidth}
								type="email"
								placeholder="test@mailbox.com"
								id="exampleEmailInput"
								onChange={(event) => {
									setEmail(event.target.value);
								}}
							/>

							<div>
								<input
									className={styles.fullWidth}
									type="password"
									placeholder="Password"
									name="password"
									onChange={(event) => {
										setPassword(event.target.value);
									}}
								/>
							</div>
							<div>
								<button
									className="button-primary"
									disabled={isInvalid}
									onClick={onAttemptSignIn}
								>
									Sign In
								</button>
								&nbsp;
								<button
									className="button-primary"
									disabled={isInvalid}
									onClick={onAttemptSignUp}
								>
									Sign Up
								</button>
								{error && (
									<p>
										{
											// @ts-ignore
											error.message
										}
									</p>
								)}
							</div>

							<button
								className="button"
								style={{ color: "white" }}
								onClick={loginAnonymously}
							>
								Continue as Guest <sup>*</sup>
							</button>

							<p>
								<sup>*</sup> Your data will be deleted once you log out.
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SignIn;
