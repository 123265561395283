import React, { useRef } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { AddIcon, TrashIcon } from "../../Icons/Icons.js";
import MovieTask from "../MovieTask";
import NameEditField from "../NameEditField";
import styles from "./style.module.css";

function ColumnHeader({ title, columnIndex, deleteCol, addTask, changeName }) {
	function AddMovieButtonPress(event) {
		event.preventDefault();
		addTask("", columnIndex);
	}

	function DeleteColumnButtonPress(event) {
		event.preventDefault();
		deleteCol(columnIndex);
	}

	function ColumnNameEdit(_arg) {
		changeName(_arg, columnIndex);
	}

	return (
		<div className={styles.ColumnHeader}>
			<NameEditField
				textClassName={`${styles.ColumnHeaderText}`}
				inputClassName={`${styles.ColumnHeaderTextInput}`}
				text={title}
				onNameChange={ColumnNameEdit}
			/>
			<div className={styles.ColumnHeaderControls}>
				<div onClick={DeleteColumnButtonPress}>
					<TrashIcon />
				</div>
				<div onClick={AddMovieButtonPress}>
					<AddIcon />
				</div>
			</div>
		</div>
	);
}

function geColumnStyle(isDragging) {
	return { background: isDragging ? "lightgreen" : "" };
}

function Column({
	uID: _columnUid,
	columnTitle: _columnTitle,
	TaskUidArray: _taskUidArray,
	tasks: _tasks,
	index: _index,
	deleteCol: _deleteCol,
	deleteTask: _deleteTask,
	addTask: _addTask,
	enableDrag: _dragEnabled,
	changeName: _changeColName,
	changeTaskName: _changeTaskName,
	setTaskWatched: _setWatched,
	onMovieCreated: _onMovieCreated,
	OnNewMovieEvent: _onStartMovieCreation,
	OnChangeNameEvent: _newChangeName,
	CreationTransaction: _newState,
	OnMovieCreationFailure: _newFailMsg,
}) {
	const itemEls = useRef([]);

	if (null === _taskUidArray) {
		return <span>ColumnData Error...</span>;
	}

	async function Start_AddMovieTransaction(_title, _columnIndex) {
		_onStartMovieCreation(_title, _columnIndex);

		//side effect "CREATE_MOVIE_SUCCESS" when the task w/that unique ID is rendered (_onMovieCreated)
		await _addTask(_title, _columnIndex).catch(function (error) {
			console.error("Add Movie Write Error: ", error);
		});
	}

	function WriteTaskElement(taskUid, index) {
		if (!_tasks) {
			console.error("Column/WriteTaskElement/Invalid Task Data ");
			return null;
		}

		const taskDataObject = _tasks[taskUid];

		if (!taskDataObject) {
			console.error("Invalid Task Data found in Render id: " + taskUid);
			return;
		}

		function changeNameWrapper(_uID, _name) {
			_newChangeName(_uID, _name);

			_changeTaskName(_uID, _name);
		}

		return (
			<MovieTask
				ref={(element) => (itemEls.current[index] = element)}
				key={taskUid}
				id={taskUid}
				index={index}
				RemoveTaskCommand={_deleteTask}
				movieName={taskDataObject.movieTitle}
				isWatched={taskDataObject.isWatched}
				changeName={changeNameWrapper}
				setWatched={_setWatched}
				onCreated={_onMovieCreated}
				CreationTransaction={_newState}
			/>
		);
	}

	return (
		/* draggableId must be a unique string */
		// index rule:
		// 		Must be unique within a <Droppable /> (no duplicates)
		// 		Must be consecutive. [0, 1, 2] and not [1, 2, 8]

		<Draggable
			draggableId={_columnUid}
			index={_index}
			key={_columnUid}
			isDragDisabled={!_dragEnabled}
		>
			{(provided) => (
				<div
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
					className={styles.FlexItem}
					// key={columnid}
					// this needs to be a regular DOM object (a div) not a react component due to below (refs)
					// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/using-inner-ref.md
				>
					<div className={styles.ColumnContainer}>
						<ColumnHeader
							// key={columnid}
							title={_columnTitle}
							columnIndex={_index}
							deleteCol={_deleteCol}
							addTask={Start_AddMovieTransaction}
							changeName={_changeColName}
						/>

						{/* droppableId must be a unique string */}
						<Droppable droppableId={_columnUid} type="task">
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
									className={styles.ColumnContents}
									style={geColumnStyle(snapshot.isDraggingOver)}
									// this needs to be a regular DOM object (a div) not a react component due to below link (refs)
									// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/using-inner-ref.md
								>
									{_taskUidArray.map(WriteTaskElement)}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</div>
				</div>
			)}
		</Draggable>
	);
}

export default Column;
