import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { db } from "../components/Firebase/fbConfig.js";
//https://console.firebase.google.com/project/top-ten-d9fc1/database/top-ten-d9fc1-default-rtdb/data

function useUserList() {
	const [UserListData, SetUserListData] = useState(null);

	// @ts-ignore
	useEffect(() => {
		const tasksTableRefString = `/users/`;
		const tasksTableRef = ref(db, tasksTableRefString);

		return onValue(tasksTableRef, (snap) => {
			if (snap !== undefined) {
				SetUserListData(snap.val());
			}
		});
	}, []);

	return UserListData;
}

export default useUserList;
