import {
	child,
	get,
	onValue,
	push,
	ref,
	remove,
	runTransaction,
	set,
	update,
} from "firebase/database";
import { useEffect, useReducer, useState } from "react";
import { db } from "../components/Firebase/fbConfig.js";
import { SaveColumnOrder, SaveTaskOrder } from "../utils/orderTransactions.js";

//https://console.firebase.google.com/project/top-ten-d9fc1/database/top-ten-d9fc1-default-rtdb/data

const LOAD_DELAY = 500;

const DataStates = Object.freeze({
	Init: "__INIT__",
	Delayed: "__DELAY__",
	Idle: "__IDLE__",
	Failure: "__FAILURE__",
});

const initState = {
	DataStates: DataStates.Init, //todo: TransactionState instead of DataStates?

	IsIdle: function () {
		const result = this.TransactionState === DataStates.Idle;
		return result;
	},
};

const ActionTypes = Object.freeze({
	useBoardListLoad: "__DATA_BOARD_LIST_INIT",
	useBoardListDelay: "__DATA_BOARD_LIST_DELAY",
	useBoardListLoadSuccess: "__DATA_BOARD_LIST_INIT_SUCCESS",
	RequestFailure: "__DATA_BOARD_LIST_FAILURE__",
});

function reducer(state, action) {
	switch (action.type) {
		case ActionTypes.useBoardListLoad:
			return {
				...state,
				TransactionState: DataStates.Init,
			};

		case ActionTypes.useBoardListDelay:
			const validDelayTransition = state.TransactionState === DataStates.Init;
			if (!validDelayTransition) return state;

			return {
				...state,
				TransactionState: DataStates.Delayed,
			};

		case ActionTypes.useBoardListLoadSuccess:
			//do not handle init load if we are not initializing

			const validTransition =
				state.TransactionState === DataStates.Init ||
				state.TransactionState === DataStates.Delayed;

			if (!validTransition) return state;

			return {
				...state,
				TransactionState: DataStates.Idle,
				data: action.payload,
			};

		case ActionTypes.RequestFailure:
			return initState;

		default:
			console.warn("Invalid Dispatch Event Type: " + action.type);
			return state;
	}
}

async function UnfollowAndNotifyBoardCreator(uid_creator, _boardKey) {
	if (!uid_creator)
		return Promise.reject(
			"uid_creator is invalid: uid_creator = " + uid_creator
		);

	if (!_boardKey)
		return Promise.reject("_boardKey is invalid: _boardKey = " + _boardKey);

	let updates = {};

	let newUserBoardData = {
		creatorId: uid_creator, //creator is the person that shared it
		followerId: null, //reset follower data
		linkState: UserBoardStateEnum.normal, //reset follower data
	};

	const followerBoardRefString = `/user-boards/${uid_creator}/${_boardKey}`;
	updates[followerBoardRefString] = newUserBoardData;

	await update(ref(db), updates).catch(function (error) {
		console.warn(`NotifyBoardCreator Failed with: ${followerBoardRefString}`);
	});
}

async function PromoteBoardFollower(_uid_Follower, _boardKey) {
	if (!_uid_Follower)
		return Promise.reject(
			"_uid_Follower is invalid: _uid_Follower = " + _uid_Follower
		);

	if (!_boardKey)
		return Promise.reject("_boardKey is invalid: _boardKey = " + _boardKey);

	let updates = {};

	let newUserBoardData = {
		creatorId: _uid_Follower, //creator is the person that shared it
		followerId: null, //reset follower data
		linkState: UserBoardStateEnum.normal, //reset follower data
	};

	const followerBoardRefString = `/user-boards/${_uid_Follower}/${_boardKey}`;
	updates[followerBoardRefString] = newUserBoardData;

	await update(ref(db), updates).catch(function (error) {
		console.warn(`Convert User Board Failed with: ${followerBoardRefString}`);
	});
}

function makeid(length) {
	var result = "";
	var characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

async function ChangeAvatar(_uid_currentUser, _boardKey) {
	if (!_uid_currentUser)
		return Promise.reject(
			"_uid_currentUser is invalid: _uid_currentUser = " + _uid_currentUser
		);

	if (!_boardKey)
		return Promise.reject("_boardKey is invalid: _boardKey = " + _boardKey);

	const userBoardAvatarRefString = `/boards/${_boardKey}/avatarSeed`;

	let updates = {};
	updates[userBoardAvatarRefString] = makeid(16);

	return await update(ref(db), updates).catch(function (error) {
		console.warn(`ChangeAvatar Failed with: ${userBoardAvatarRefString}`);
	});
}

async function DeleteBoard(_uid_currentUser, _boardKey) {
	if (!_uid_currentUser)
		return Promise.reject(
			"_uid_currentUser is invalid: _uid_currentUser = " + _uid_currentUser
		);

	if (!_boardKey)
		return Promise.reject("_boardKey is invalid: _boardKey = " + _boardKey);

	const userBoardRefString = `/user-boards/${_uid_currentUser}/${_boardKey}`;
	const userBoardRef = ref(db, userBoardRefString);

	//cache the current value before we delete
	var userBoardData = null;
	await get(userBoardRef)
		.then((snapshot) => {
			if (snapshot.exists()) {
				userBoardData = snapshot.val();
			} else {
				console.warn("No data available");
			}
		})
		.catch((error) => {
			console.error(error);
		});

	if (!userBoardData) {
		let message = `useBoardList/DeleteBoard failed: No value to delete at ${userBoardRefString} `;
		console.warn(message);
		return Promise.reject(message);
	}

	//whatever happens we remove the listing from the userboard of this user
	await runTransaction(userBoardRef, (post) => {
		return null; //returning null deletes the data
	}).catch(function (error) {
		console.error(error);
		return Promise.reject("/user-boards/ Delete Synchronization failed");
	});

	// @ts-ignore
	const currentLinkState = userBoardData?.linkState;

	///just early out if we have no link information
	if (!currentLinkState) return;

	if (currentLinkState === UserBoardStateEnum.linked) {
		await HandleUnlink(userBoardData, _uid_currentUser, _boardKey);
	} else {
		console.assert(currentLinkState !== UserBoardStateEnum.linked);

		const boardRef = ref(db, `/boards/${_boardKey}`);
		await remove(boardRef).catch(function (error) {
			console.error(error);
			return Promise.reject("Board Delete Synchronization failed");
		});
	}
}

async function HandleUnlink(_userBoardData, _uid_currentUser, _boardKey) {
	if (!_uid_currentUser)
		return Promise.reject(
			"_uid_currentUser is invalid: _uid_currentUser = " + _uid_currentUser
		);

	if (!_boardKey)
		return Promise.reject("_boardKey is invalid: _boardKey = " + _boardKey);

	if (!_userBoardData)
		return Promise.reject(
			"_userBoardData is invalid: _userBoardData = " + _userBoardData
		);

	// @ts-ignore
	const currentLinkState = _userBoardData?.linkState;
	if (!currentLinkState) return;

	//we should not be handling unlink if we're not linked
	console.assert(
		currentLinkState === UserBoardStateEnum.linked,
		`we should not be handling unlink if we're not linked ${currentLinkState}`
	);

	const boardCreatorId = _userBoardData?.creatorId;
	const boardFollowerId = _userBoardData?.followerId;
	const isCurrentUserCreator = boardCreatorId === _uid_currentUser;

	//if a board is linked it could be we are a follower or an owner
	if (boardFollowerId === _uid_currentUser) {
		//if we are a follower - let the creator know we don't follow anymore
		await UnfollowAndNotifyBoardCreator(boardCreatorId, _boardKey).catch(
			function (error) {
				console.warn("UnfollowAndNotifyBoardCreator failed " + error);
			}
		);
	} else if (isCurrentUserCreator) {
		//if we are a creator - promote the follower
		await PromoteBoardFollower(boardFollowerId, _boardKey).catch(function (
			error
		) {
			console.warn("follower promotion failed " + error);
		});
	}
}

function WriteNewGroup(_groupName, _boardId) {
	return new Promise(function (resolve, reject) {
		if (_groupName.length < 1)
			return reject(
				"WriteNewGroup: _groupName invalid: _groupName = " + _groupName
			);

		if (!_boardId)
			return reject(
				"WriteNewGroup: _boardId is invalid: _boardId = " + _boardId
			);

		const newGroupRef = push(ref(db, `/boards/${_boardId}/groups`));

		set(newGroupRef, {
			groupTitle: _groupName,
		})
			.then(function () {
				const columnKeysOrdered = [];
				columnKeysOrdered.push(newGroupRef.key);
				const colunOrderDbRef = ref(db, `/boards/${_boardId}/columnOrder/`);
				SaveColumnOrder(colunOrderDbRef, columnKeysOrdered);

				return resolve(newGroupRef.key);
			})
			.catch(function (error) {
				remove(newGroupRef);
				return reject(
					"useBoardList/WriteNewGroup/Synchronization failed: " + error
				);
			});
	});
}

function WriteNewMovie(_movieName, _boardId, _groupId) {
	return new Promise(function (resolve, reject) {
		if (_movieName.length < 1)
			return reject(
				"WriteNewMovie: _groupName invalid: _groupName = " + _movieName
			);

		if (!_boardId)
			return reject(
				"WriteNewMovie: _boardId is invalid: _boardId = " + _boardId
			);

		if (!_groupId)
			return reject(
				"WriteNewMovie: _groupId is invalid: _groupId = " + _groupId
			);

		const newMovieRef = push(ref(db, `/boards/${_boardId}/tasks`));

		set(newMovieRef, {
			movieTitle: _movieName,
			isWatched: false,
		})
			.then(function () {
				let colNewTaskOrder = [];

				colNewTaskOrder.push(newMovieRef.key);

				const newOrderRef = ref(
					db,
					`/boards/${_boardId}/taskOrder/${_groupId}/`
				);
				SaveTaskOrder(newOrderRef, colNewTaskOrder);

				//("SaveTaskOrder " + newMovieRef.key);
				return resolve(newMovieRef.key);
			})
			.catch(function (error) {
				remove(newMovieRef);
				return reject(
					"useBoardList/WriteNewMovie/Synchronization failed: " + error
				);
			});
	});
}

export const UserBoardStateEnum = Object.freeze({
	normal: 0,
	invited: 200, //(invite pending)
	linked: 400, //(invite accepted)
});

function WriteNewBoard(_uid_creator, _boardName) {
	return new Promise(function (resolve, reject) {
		if (!_uid_creator)
			return reject("uid_creator is invalid: uid_creator = " + _uid_creator);

		if (!_boardName)
			return reject("_boardName is invalid: _boardName = " + _boardName);

		var boardData = {
			title: _boardName,
		};

		var userboardData = {
			creatorId: _uid_creator,
			linkState: UserBoardStateEnum.normal,
		};

		// Get a key for a new Board.

		const newBoardKey = push(child(ref(db), "boards")).key;

		// Write the new Board's data simultaneously in the posts list and the user's Board list.
		var updates = {};
		updates["/boards/" + newBoardKey] = boardData;
		updates["/user-boards/" + _uid_creator + "/" + newBoardKey] = userboardData;

		update(ref(db), updates)
			.then(function () {
				return resolve(newBoardKey);
			})
			.catch(function (error) {
				return reject(
					"useBoardList/WriteNewBoard/Synchronization failed: " + error
				);
			});
	});
}

function generateShareLink(_boardId, _currentUserId) {
	//check if we're on prod
	const hostName = window.location.hostname;
	const isLocal = hostName === "localhost" || hostName === "127.0.0.1";
	const domainUriPrefix = isLocal
		? `http://localhost:3000`
		: `https://top-ten.app`;

	return `${domainUriPrefix}/invite/board?boardId=${_boardId}&foreignId=${_currentUserId}`;
}

function ShareBoard(_boardId, _uid_currentUser) {
	return new Promise(function (resolve, reject) {
		if (!_boardId) return reject("_boardId is invalid: _boardId = " + _boardId);

		if (!_uid_currentUser)
			return reject(
				"_uid_currentUser is invalid: _uid_currentUser = " + _boardId
			);

		var linkText = generateShareLink(_boardId, _uid_currentUser);
		navigator.clipboard.writeText(linkText);

		var updates = {};
		updates[`/user-boards/${_uid_currentUser}/${_boardId}/linkState`] =
			UserBoardStateEnum.invited;

		//(`sharing: /user-boards/${_uid_currentUser}/${_boardId}`);

		update(ref(db), updates)
			.then(function () {
				return resolve();
			})
			.catch(function (error) {
				return reject(
					"useBoardList/ShareBoard/Synchronization failed: " + error
				);
			});
	});
}

function WriteBoardLink(
	_uid_currentUser,
	_uid_foreignUser_boardId,
	_uid_foreignUser
) {
	return new Promise(function (resolve, reject) {
		if (!_uid_currentUser)
			return reject(
				"_uid_currentUser is invalid: _uid_currentUser = " + _uid_currentUser
			);

		if (!_uid_foreignUser_boardId)
			return reject(
				"_uid_foreignUser_boardId is invalid: _uid_foreignUser_boardId = " +
					_uid_foreignUser_boardId
			);

		var updates = {};

		// Write the foriegn users board into the current user's Board list.
		updates[`/user-boards/${_uid_currentUser}/${_uid_foreignUser_boardId}`] = {
			creatorId: _uid_foreignUser, //creator is the person that shared it
			followerId: _uid_currentUser, //current user is the person that follows it
			linkState: UserBoardStateEnum.linked,
		};

		// update the foriegn users board data to match.
		updates[
			`/user-boards/${_uid_foreignUser}/${_uid_foreignUser_boardId}/linkState`
		] = UserBoardStateEnum.linked;

		updates[
			`/user-boards/${_uid_foreignUser}/${_uid_foreignUser_boardId}/followerId`
		] = _uid_currentUser;

		update(ref(db), updates)
			.then(function () {
				return resolve();
			})
			.catch(function (error) {
				return reject(
					"useBoardList/WriteBoardLink/Synchronization failed: " + error
				);
			});
	});
}

const useBoardList = (userId) => {
	const [rawUserBoards, SetRawUserBoards] = useState(null);
	const [rawBoardData, setRawBoardData] = useState(null);
	const [boardState, dispatch] = useReducer(reducer, initState);

	// @ts-ignore
	useEffect(() => {
		//(`useBoardList/reducer/state: ${JSON.stringify(state)}`);

		if (boardState.TransactionState === DataStates.Delayed) {
			const timer = setTimeout(() => {
				// @ts-ignore
				dispatch({
					type: ActionTypes.useBoardListLoadSuccess,
				});
			}, LOAD_DELAY);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [boardState]);

	// @ts-ignore
	useEffect(() => {
		// @ts-ignore
		dispatch({
			type: ActionTypes.useBoardListLoad,
		});

		if (!userId) {
			SetRawUserBoards(null);
			return null;
		}

		const dbRefString = `user-boards/` + userId;
		const userBoardRef = ref(db, dbRefString);

		return onValue(userBoardRef, (snap) => {
			if (snap === undefined) {
				//console.warn(`useBoardList/board data error: userId ${userId}`);
				return;
			}

			SetRawUserBoards(snap.val());
		});
	}, [userId]);

	useEffect(() => {
		const cleanupList = [];

		const keys = Object.keys(rawUserBoards || {});

		if (keys.length <= 0) {
			setRawBoardData(null);
			// @ts-ignore
			dispatch({
				type: ActionTypes.useBoardListDelay,
			});
			return null;
		}

		let result = {};

		keys.forEach((boardId) => {
			const dbBoardRefString = `/boards/${boardId}`;
			const boardDbRef = ref(db, dbBoardRefString);

			cleanupList.push(
				onValue(boardDbRef, (snap) => {
					const newData = snap.val();
					const appended = {
						...newData,

						isMine: rawUserBoards[boardId]?.creatorId === userId,
						linkState: rawUserBoards[boardId]?.linkState,
					};
					result[boardId] = appended;

					//make sure to create a new object to cause a render
					setRawBoardData({ ...result });
				})
			);
		});

		// @ts-ignore
		dispatch({
			type: ActionTypes.useBoardListDelay,
		});
		return () => {
			cleanupList.forEach((element) => {
				const cleanUpFunc = element;
				cleanUpFunc();
			});
		};
	}, [dispatch, rawUserBoards, userId]);

	return {
		BoardData: rawBoardData,
		DataState: boardState,
		RemoveBoard: DeleteBoard,
		CreateBoard: WriteNewBoard,
		ShareBoard: ShareBoard,
		AddMovie: WriteNewMovie,
		AddGroup: WriteNewGroup,
		LinkBoard: WriteBoardLink,
		ChangeAvatar: ChangeAvatar,
	};
};

export default useBoardList;
