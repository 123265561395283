import React from "react";
import {
	Link,
	Navigate,
	Outlet,
	Route,
	Routes,
	useLocation,
} from "react-router-dom";
import {
	SEGMENT_PATH_INVITE_STAR,
	SEGMENT_PATH_LANDING,
	SEGMENT_PATH_LOGIN,
	SEGMENT_PATH_USER,
} from "../../constants/paths.js";
import { useAuth } from "../../context/AuthContext.js";
import { IsNetworkOnline } from "../../utils/network";
import Board from "../Board";
import BoardList from "../BoardList/index.js";
import Invite from "../Invite/index.js";
import SignIn from "../SignIn/index.js";
import UserProfile from "../UserProfile";

const debugNav = false;

const Landing = () => {
	//define {authError, user, funcLogOut } with weird js object destructuring bullshit
	var { AuthAPI_Error: authError, AuthAPI_CurrentUser: authCurrentUser } =
		useAuth();

	//#region Authentication

	if (!IsNetworkOnline())
		return (
			<div>
				<h1 className="MajorError">
					No Internet connection detected!Please connect and try again
				</h1>
			</div>
		);

	//error while logging in
	if (authError)
		return (
			<div>
				<h1>"Error"{/*authObject.error*/}</h1>
				<button

				//onClick={loginWithGoogle}
				>
					Retry Login
				</button>
			</div>
		);

	//#endregion

	return (
		<Routes>
			<Route path={SEGMENT_PATH_LANDING} element={<Layout />}>
				<Route path={SEGMENT_PATH_LOGIN} element={<SignIn />} />

				<Route path={SEGMENT_PATH_INVITE_STAR} element={<Invite />} />

				{/* An "index route" is the element to render default match */}
				<Route
					index
					element={
						<RequireAuth>
							<BoardList />
						</RequireAuth>
					}
				/>

				<Route
					path="user"
					element={
						<RequireAuth>
							<UserProfile CurrentUserData={authCurrentUser} />
						</RequireAuth>
					}
				/>

				<Route
					path="board/:boardId/*"
					element={
						<RequireAuth>
							<Board CurrentUserData={authCurrentUser} />
						</RequireAuth>
					}
				/>
				<Route path="*" element={<NoMatch />} />
			</Route>
		</Routes>
	);
};

export default Landing;

function RequireAuth({ children }) {
	let location = useLocation();

	var { AuthAPI_CurrentUser: authCurrentUser } = useAuth();

	if (false === authCurrentUser) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{ from: location }} />;
	}

	return children;
}

//A "layout route" is a good place to put markup you want to
// share across all the pages on your site, like navigation.
function Layout() {
	return (
		<>
			{debugNav ? (
				<nav>
					<ul>
						<li>
							<Link to={SEGMENT_PATH_LANDING}>Home</Link>
						</li>
						<li>
							<Link to={SEGMENT_PATH_USER}>User</Link>
						</li>
						<li>
							<Link to="/break">Break</Link>
						</li>
						<li>
							<Link to="/invite/board?boardId=-Mr4iE3vIheVUcp5AJvs&foreignId=WH1mSXeqYFcgm0RvIPjUbgvDY2M2">
								Invite
							</Link>
						</li>
					</ul>
				</nav>
			) : null}

			<Outlet />
		</>
	);
}

function NoMatch() {
	return (
		<div>
			<h2>App: Nothing to see here!</h2>
			<p>
				<Link to={SEGMENT_PATH_LANDING}>Go to the home page</Link>
			</p>
		</div>
	);
}
