import {
	createUserWithEmailAndPassword,
	getAuth,
	signInAnonymously,
	signInWithEmailAndPassword,
	signOut,
} from "firebase/auth";
import { ref, set } from "firebase/database";
import { useCallback, useEffect, useState } from "react";
import { db } from "../components/Firebase/fbConfig";

const useFirebaseAuth = () => {
	const auth = getAuth();

	const [FireBase_CurrentUser, setUser] = useState(null);
	const [FireBase_Error, setError] = useState(false);

	const memoizedCallback = useCallback((newUser) => {
		setError(null);

		if (newUser) {
			setUser(newUser);
		} else {
			setUser(false);
		}
	}, []);

	useEffect(() => {
		// listen for auth state changes
		const unsubscribe = getAuth().onAuthStateChanged(memoizedCallback);

		// Cleanup subscription on unmount
		return function cleanup() {
			unsubscribe();
		};
	}, [memoizedCallback]);

	const FireBase_LoginAnon = () => {
		setUser(null);
		return signInAnonymously(auth).then(function (userCredential) {
			//set the user so that other dependents don't have to rely on onAuthStateChanged events
			setUser(userCredential.user);
			return userCredential;
		});
	};

	const FireBase_LoginEmail = (email, password) => {
		return signInWithEmailAndPassword(auth, email, password).then(function (
			userCredential
		) {
			//set the user so that other dependents don't have to rely on onAuthStateChanged events
			setUser(userCredential.user);
			return userCredential;
		}); //return promise
	};

	const FireBase_LogOut = () => {
		setUser(null);
		return signOut(auth); //return promise
	};

	const FireBase_registerWithEmailAndPassword = (_name, _email, _password) => {
		return createUserWithEmailAndPassword(auth, _email, _password).then(
			function (userCredential) {
				//set the user so that other dependents don't have to rely on onAuthStateChanged events
				setUser(userCredential.user);
				const newUserRef = ref(db, `/users/${userCredential.user.uid}`);

				set(newUserRef, {
					email: _email,
					username: _name,
				}).catch(function (error) {
					return Promise.reject("User DB Synchronization failed");
				});

				return userCredential;
			}
		); //return promise
	};

	const authAPI = {
		AuthAPI_Error: FireBase_Error,
		AuthAPI_CurrentUser: FireBase_CurrentUser,
		AuthAPI_LoginAnon: FireBase_LoginAnon,
		AuthAPI_LoginEmail: FireBase_LoginEmail,
		AuthAPI_RegisterWithEmailAndPassword: FireBase_registerWithEmailAndPassword,
		AuthAPI_LogOut: FireBase_LogOut,
	};

	return authAPI;
};

export default useFirebaseAuth;
