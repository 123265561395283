import { Draggable } from "react-beautiful-dnd";
import React, { useImperativeHandle, useRef, useEffect } from "react";
import {
	TrashIcon,
	CheckCircle,
	CheckCircleComplete,
} from "../../Icons/Icons.js";
import styles from "./style.module.css";
import NameEditField from "../NameEditField";

function DraggableWrapper({ enableDragDrop, id, index, children }) {
	if (!enableDragDrop)
		return <div className={styles.TaskContents}>{children}</div>;

	return (
		<Draggable draggableId={id} index={index} key={id}>
			{(provided) => (
				<div
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
					className={styles.TaskContents}
					// this needs to be a regular DOM object (a div) not a react component due to below (refs)
					// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/using-inner-ref.md
				>
					{children}
				</div>
			)}
		</Draggable>
	);
}

function MovieTaskImp(
	{
		id: _uniqueID,
		index: _index,
		RemoveTaskCommand: _removeTask,
		movieName: _movieName,
		isWatched: _isWatched,
		changeName: _changeName,
		setWatched: _setWatched,
		onCreated: _newOnCreation,
		CreationTransaction: _creationState,
	},
	forwardRef
) {
	const textfieldRef = useRef();

	function taskNameEditWrap(_arg) {
		_changeName(_uniqueID, _arg);
	}

	useEffect(() => {
		_newOnCreation(_uniqueID);
	}, [_newOnCreation, _uniqueID]);

	useEffect(() => {
		if (_creationState.IsFocussed(_uniqueID)) {
			// @ts-ignore
			textfieldRef?.current?.focus();
		}
	}, [_creationState, _uniqueID]);

	useImperativeHandle(forwardRef, () => ({
		focus: () => {
			// @ts-ignore
			textfieldRef?.current?.focus();
		},
	}));

	const removeMovieWrap = (event) => {
		event.preventDefault();
		_removeTask(_uniqueID);
	};

	const setWatchedWrap = (event) => {
		event.preventDefault();
		_setWatched(_uniqueID, !_isWatched);
	};

	return (
		<DraggableWrapper enableDragDrop={true} id={_uniqueID} index={_index}>
			<NameEditField
				ref={textfieldRef}
				textClassName={`${styles.TaskText}`}
				inputClassName={`${styles.TaskTextInput}`}
				text={_movieName}
				onNameChange={taskNameEditWrap}
			/>
			<div className={styles.TaskFooter}>
				<div onClick={setWatchedWrap}>
					{_isWatched ? <CheckCircleComplete /> : <CheckCircle />}
				</div>

				<div onClick={removeMovieWrap}>
					<TrashIcon />
				</div>
			</div>
		</DraggableWrapper>
	);
}

const MovieTask = React.forwardRef(MovieTaskImp);

export default MovieTask;
