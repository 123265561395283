import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router";
import breakPoints from "../../constants/breakpoints.module.css";
import useAddMovie from "../../hooks/useAddMovie";
import useBoardList from "../../hooks/useBoardList.js";
import useTopTen from "../../hooks/useTopTen.js";
import Breadcrumb from "./Breadcrumb";
import Column from "./Column";
import styles from "./style.module.css";

function Board({ CurrentUserData: _userData }) {
	//_currentBoardID is passed through <Route path="/board/:boardId"> params in Landing page render

	const { boardId: _currentBoardID } = useParams();

	/* eslint-disable no-unused-vars */
	//leaving this here as it may be useful in the future
	const [isBiggerThanPhablet, setIsBiggerThanPhablet] = useState(false);
	/* eslint-enable no-unused-vars */

	var _userId = _userData?.uid;
	const { BoardData: _userBoards } = useBoardList(_userId);

	useEffect(() => {
		// set initial value
		const mediaWatcher = window.matchMedia(breakPoints.minWidthPhablet);
		setIsBiggerThanPhablet(mediaWatcher.matches);

		//watch for updates
		function onScreenChange(e) {
			setIsBiggerThanPhablet(e.matches);
		}
		mediaWatcher.addEventListener("change", onScreenChange);

		// clean up after ourselves
		return function cleanup() {
			mediaWatcher.removeEventListener("change", onScreenChange);
		};
	}, []);

	const _currentUserID = _userData?.uid;

	const {
		ColumnData,
		AddMovie,
		AddGroup,
		RemoveTask,
		RemoveColumn,
		ReorderColumns,
		ReorderTasks,
		MoveTask,
		UpdateGroupName,
		UpdateTaskName,
		UpdateTaskWatched,
		UpdateBoardName,
	} = useTopTen(_currentUserID, _currentBoardID);

	const {
		AddMovieTransaction,
		StartTransaction: startMovieCreation,
		OnMovieCreation: movieCreatedEvent,
		ChangeName: changeNameEvent,
		OnFailure: SetMovieCreationFailed,
	} = useAddMovie();

	function WriteColumnElements(_columnUid, _index) {
		if (!ColumnData) {
			//console.error("useTopTen/addMovie/Invalid Column Data ");
			return null;
		}

		if (!ColumnData.columnNames) {
			//console.error("useTopTen/addMovie/Invalid Column Data ");
			return null;
		}

		const columnName = ColumnData?.columnNames
			? ColumnData?.columnNames[_columnUid]?.groupTitle
			: "Name Error";

		const colTaskIds = ColumnData?.taskOrder[_columnUid]
			? Array.from(ColumnData?.taskOrder[_columnUid])
			: [];

		return (
			<Column
				uID={_columnUid}
				columnTitle={columnName}
				key={_columnUid}
				TaskUidArray={colTaskIds}
				tasks={ColumnData.tasks}
				index={_index}
				deleteCol={RemoveColumn}
				deleteTask={RemoveTask}
				addTask={AddMovie}
				enableDrag={false}
				changeName={UpdateGroupName}
				changeTaskName={UpdateTaskName}
				setTaskWatched={UpdateTaskWatched}
				onMovieCreated={movieCreatedEvent}
				OnNewMovieEvent={startMovieCreation}
				OnChangeNameEvent={changeNameEvent}
				CreationTransaction={AddMovieTransaction}
				OnMovieCreationFailure={SetMovieCreationFailed}
			/>
		);
	}

	function onDragEnd(result) {
		//DraggableLocation
		const { source: _srcDragLocation, destination: _destDragLocation } = result;

		// dropped outside the list
		if (!_destDragLocation) {
			return;
		}

		//didn't re-order anything
		if (
			_destDragLocation.droppableId === _srcDragLocation.droppableId &&
			_destDragLocation.index === _srcDragLocation.index
		) {
			return;
		}

		//clone the state to start editing process
		//const stateClone = Array.from(ColumnData.taskOrder[sourceColumnDataIndex]);

		if (result.type === "task") {
			const sourceColumnUid = _srcDragLocation.droppableId;
			const destColumnUid = _destDragLocation.droppableId;

			if (sourceColumnUid === destColumnUid) {
				//CASE 1: Drag a Task within same column (source and destination columnIds are identical)

				ReorderTasks(
					sourceColumnUid,
					_srcDragLocation.index,
					_destDragLocation.index
				);
			} else {
				// //CASE 2: Drag a Task to a different column (source and destination columnIds are different)

				MoveTask(
					sourceColumnUid,
					destColumnUid,
					_srcDragLocation.index,
					_destDragLocation.index
				);
			}
		} else {
			//CASE 3: Reorder Columns
			ReorderColumns(_srcDragLocation.index, _destDragLocation.index);
		}
	}

	if (null === _userBoards) {
		return <span>No Board Data...</span>;
	}

	const currentBoardData = _userBoards[_currentBoardID];

	function ChangeBoardNameEventWrap(event) {
		event.preventDefault();
		let newName = event?.target?.value;
		UpdateBoardName(_currentBoardID, newName);
	}

	//https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/drag-drop-context.md
	return (
		<div className={styles.AppPage}>
			<DragDropContext onDragEnd={onDragEnd}>
				<Breadcrumb
					currentBoardName={currentBoardData?.title}
					onBoardNameChange={ChangeBoardNameEventWrap}
					onAddColumnRequest={AddGroup}
				/>

				<Droppable droppableId="allCols" type="column" direction="horizontal">
					{(provided) => (
						<div
							className={styles.ColumnGrid}
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{ColumnData?.columnOrderArray.map(WriteColumnElements)}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
}
export default Board;
