import {
	onValue,
	push,
	ref,
	remove,
	runTransaction,
	set,
	update,
} from "firebase/database";
import { useEffect, useState } from "react";
import { db } from "../components/Firebase/fbConfig.js";
import { SaveColumnOrder, SaveTaskOrder } from "../utils/orderTransactions.js";

//https://console.firebase.google.com/project/top-ten-d9fc1/database/top-ten-d9fc1-default-rtdb/data

//Moves an item from one array to another returning two new arrays
function Move(
	_srcArray,
	_destArray,
	srcIndex,
	droppableDestinationIndex,
	sourceColumnDataIndex,
	destColumnDataIndex
) {
	const [removed] = _srcArray.splice(srcIndex, 1);
	_destArray.splice(droppableDestinationIndex, 0, removed);
}

//Move an item  within one array
function Reorder(list, startIndex, endIndex) {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
}

const useTopTen = (userId, boardId) => {
	const [RawTaskData, SetRawTaskData] = useState(null);
	const [RawGroupData, SetRawGroupData] = useState(null);
	const [RawColumnOrder, SetRawColumnOrder] = useState(null);
	const [RawTaskOrder, SetRawTaskOrder] = useState({});
	const [finalColumnTaskOrder, setFinalColumnTaskOrder] = useState(null);

	// @ts-ignore
	useEffect(() => {
		if (null === RawTaskOrder || null === RawColumnOrder) {
			//("Skipping Board Update");
			return null;
		}

		if (!Array.isArray(RawColumnOrder)) {
			console.error(
				"UseTopTen: RawColumnOrderz: Invalid Firebase Database Array"
			);
			return null;
		}

		const finalObject = {};
		finalObject.columnOrderArray = RawColumnOrder;
		finalObject.taskOrder = {};

		let columnKeys = Object.keys(RawTaskOrder);
		columnKeys.forEach((columnUid) => {
			finalObject.taskOrder[columnUid] = RawTaskOrder[columnUid];
		});

		finalObject.tasks = RawTaskData;
		finalObject.columnNames = RawGroupData;
		setFinalColumnTaskOrder(finalObject);
	}, [RawTaskOrder, RawColumnOrder, RawTaskData, RawGroupData]);

	// @ts-ignore
	useEffect(() => {
		const columnOrderRefString = `/boards/${boardId}/columnOrder`;
		const columnOrderTableRef = ref(db, columnOrderRefString);
		return onValue(columnOrderTableRef, (columnOrderSnap) => {
			if (!columnOrderSnap) {
				return null;
			}
			if (!columnOrderSnap.exists()) {
				return null;
			}

			let columnNewOrder = [];

			function WriteColumnOrder(_iterator) {
				columnNewOrder.push(_iterator);
			}

			Object.values(columnOrderSnap.val()).map(WriteColumnOrder);
			SetRawColumnOrder(columnNewOrder);
		});
	}, [userId, boardId]);

	// @ts-ignore
	useEffect(() => {
		const dbRefString = `/boards/${boardId}/groups/`;
		const groupsDbRef = ref(db, dbRefString);

		return onValue(groupsDbRef, (snap) => {
			if (snap !== undefined) {
				//TODO: #2 This causes Warning: Can't perform a React state update on an unmounted component.w
				SetRawGroupData(snap.val());
			}
		});
	}, [userId, boardId]);
	//#endregion

	//#region TaskData

	// @ts-ignore
	useEffect(() => {
		const tasksTableRefString = `/boards/${boardId}/tasks`;
		const tasksTableRef = ref(db, tasksTableRefString);

		return onValue(tasksTableRef, (snap) => {
			if (snap !== undefined) {
				SetRawTaskData(snap.val());
			}
		});
	}, [userId, boardId]);

	//#endregion

	//#region TaskOrder
	// @ts-ignore
	useEffect(() => {
		if (!userId) return null;

		const dbRefString = `/boards/${boardId}/taskOrder/`;
		const taskOrderDbRef = ref(db, dbRefString);

		return onValue(taskOrderDbRef, (dbTaskOrderSnap) => {
			if (!dbTaskOrderSnap) return null;
			let dbValue = dbTaskOrderSnap.val();
			if (null === dbValue) {
				SetRawTaskOrder({});
			} else {
				let clonedObj = {};

				//filer out null values created if we manually alter the DB and the array there
				//is no longer zero indexed - doesn't start at 0
				Object.keys(dbValue).forEach((columnUid) => {
					clonedObj[columnUid] = dbValue[columnUid].filter(function (ele) {
						return ele !== null;
					});
				});

				SetRawTaskOrder(clonedObj);
			}
		});
	}, [userId, boardId]);
	//#endregion

	function addMovie(_movieName, _colIndex) {
		return new Promise(function (resolve, reject) {
			if (_colIndex === null || _colIndex === undefined) {
				return reject("useTopTen/addMovie/Invalid Column Index ");
			}

			const columnUids = Array.from(RawColumnOrder);
			if (columnUids.length <= 0) {
				return reject("useTopTen/addMovie/No valid Columns to add to");
			}

			const newMovieRef = push(ref(db, `/boards/${boardId}/tasks`));

			let targetColumnUid = columnUids[_colIndex];

			set(newMovieRef, {
				movieTitle: _movieName,
				isWatched: false,
			})
				.then(function () {
					let colNewTaskOrder = RawTaskOrder[targetColumnUid]
						? Array.from(RawTaskOrder[targetColumnUid])
						: [];

					colNewTaskOrder.push(newMovieRef.key);

					var newState = RawTaskOrder;
					newState[targetColumnUid] = colNewTaskOrder;
					SetRawTaskOrder(newState);

					const newOrderRef = ref(
						db,
						`/boards/${boardId}/taskOrder/${targetColumnUid}/`
					);
					SaveTaskOrder(newOrderRef, colNewTaskOrder);

					//("addMovie done with " + newMovieRef.key);
					return resolve(newMovieRef);
				})
				.catch(function (error) {
					remove(newMovieRef);
					return reject("useTopTen/addMovie/Synchronization failed: " + error);
				});
		});
	}

	function addGroup(_groupName) {
		if (_groupName.length < 1) return;

		const newGroupRef = push(ref(db, `/boards/${boardId}/groups`));

		set(newGroupRef, {
			groupTitle: _groupName,
		})
			.then(function () {
				const columnKeysOrdered = RawColumnOrder
					? Array.from(RawColumnOrder)
					: [];
				columnKeysOrdered.push(newGroupRef.key);
				SetRawColumnOrder(columnKeysOrdered);
				const colunOrderDbRef = ref(db, `/boards/${boardId}/columnOrder/`);
				SaveColumnOrder(colunOrderDbRef, columnKeysOrdered);
			})
			.catch(function (error) {
				console.error("Group Name Synchronization failed: " + error);
			});
	}

	function changeBoardName(_boardId, _newName) {
		if (!boardId) {
			console.error(`useTopTen/changeBoardNameInvalid Board ID:  ${_boardId}`);
			return;
		}

		if (!_newName || _newName.length < 1) {
			console.warn(
				`useTopTen/changeBoardName: Trying to change Board Name to  ${_newName}`
			);
			return;
		}

		const groupRef = ref(db, `/boards/${boardId}`);

		update(groupRef, {
			title: _newName,
		}).catch(function (error) {
			console.error("`useTopTen/changeBoardName failed: " + error);
		});
	}

	function changeGroupName(_newGroupName, _colIndex) {
		if (_colIndex === null || _colIndex === undefined) {
			console.error("useTopTen/changeGroupName/Invalid Column Index ");
			return;
		}

		const columnUids = Array.from(RawColumnOrder);
		if (columnUids.length <= 0) {
			console.error("useTopTen/changeGroupName/No valid Columns change name ");
			return;
		}
		if (_colIndex < 0 || _colIndex >= columnUids.length) {
			console.error("useTopTen/changeGroupName/Invalid Column Index ");
			return;
		}

		if (_newGroupName.length < 1) {
			console.error("useTopTen/changeGroupName/Invalid new group name ");
			return;
		}

		let key = columnUids[_colIndex];
		const newGroupRef = ref(db, `/boards/${boardId}/groups/${key}`);

		set(newGroupRef, {
			groupTitle: _newGroupName,
		})
			.then(function () {
				//("changeGroupName: Update Success");
			})
			.catch(function (error) {
				console.error("changeGroupName: Update Failed: " + error.message);
			});
	}

	function changeTaskWatched(_uniqueID, _setting) {
		if (_uniqueID === null || _uniqueID === undefined) {
			console.error("useTopTen/changeTaskName/Invalid ID");
			return;
		}

		//set local data
		let clonedObj = { ...RawTaskData };
		if (!clonedObj.hasOwnProperty(_uniqueID)) {
			console.error("useTopTen/changeTaskWatched/Data Key Error");
			return;
		}

		clonedObj[_uniqueID].isWatched = _setting;
		SetRawTaskData(clonedObj);

		const taskRef = ref(db, `/boards/${boardId}/tasks/${_uniqueID}`);

		update(taskRef, {
			isWatched: _setting,
		})
			.then(function () {
				//("changeTaskWatched: Update Success");
			})
			.catch(function (error) {
				console.error("changeTaskWatched: Update Failed: " + error.message);
			});
	}

	function changeTaskName(_uniqueID, _newName) {
		if (!_uniqueID) {
			console.error("useTopTen/changeTaskName/Invalid ID");
			return;
		}

		//_newName can be empty or invalid (delete the task)

		//set local data
		let clonedObj = { ...RawTaskData };
		if (!clonedObj.hasOwnProperty(_uniqueID)) {
			console.error("useTopTen/changeTaskWatched/Data Key Error");
			return;
		}

		clonedObj[_uniqueID].movieTitle = _newName;
		SetRawTaskData(clonedObj);

		const taskRef = ref(db, `/boards/${boardId}/tasks/${_uniqueID}`);

		if (_newName.length < 1) {
			deleteTask(_uniqueID);
		} else {
			update(taskRef, {
				movieTitle: _newName,
			})
				.then(function () {
					//("changeTaskName: Update Success");
				})
				.catch(function (error) {
					console.error("changeTaskName: Update Failed: " + error.message);
				});
		}
	}

	function deleteTask(_taskID) {
		if (_taskID === undefined || _taskID === null) {
			console.warn("DeleteTask: Invalid ID");
			return;
		}

		let clonedObj = { ...RawTaskOrder };

		Object.keys(clonedObj).forEach((it, _columnIndex) => {
			let colTaskOrderBefore = Array.from(clonedObj[it]);
			let colTaskOrderAfter = colTaskOrderBefore.filter(function (ele) {
				return ele !== _taskID;
			});

			clonedObj[it] = colTaskOrderAfter;
		});

		SetRawTaskOrder(clonedObj);

		Object.keys(clonedObj).forEach((it, _columnIndex) => {
			let colTaskOrderAfter = Array.from(clonedObj[it]).filter(function (ele) {
				return ele !== _taskID;
			});

			const orderRef = ref(db, `/boards/${boardId}/taskOrder/${it}/`);
			const oldTaskRef = ref(db, `/boards/${boardId}/tasks/${_taskID}`);

			runTransaction(orderRef, (post) => {
				//this return will set the data
				// 			//returning undefined will abort the operation
				return colTaskOrderAfter;
			})
				.then(function () {
					//delete Task from Database JSON
					remove(oldTaskRef);
				})
				.catch(function (error) {
					console.error("deleteTask/Synchronization failed");
				});
		});
	}

	function removeGroupFromTaskOrder(_columnUid) {
		if (null === _columnUid) return;

		let taskRefToDelete = ref(db, `/boards/${boardId}/taskOrder/${_columnUid}`);
		remove(taskRefToDelete).catch(function (error) {
			console.error("removeGroupFromTaskOrder failed: " + error);
		});
	}

	function removeFromGroupOrder(_columnIndex) {
		const columnKeysOrdered = Array.from(RawColumnOrder);
		if (_columnIndex < 0 || _columnIndex >= columnKeysOrdered.length) {
			return null;
		}

		let removedColumnUid = columnKeysOrdered.splice(_columnIndex, 1);
		SetRawColumnOrder(columnKeysOrdered);

		const colunOrderDbRef = ref(db, `/boards/${boardId}/columnOrder/`);
		SaveColumnOrder(colunOrderDbRef, columnKeysOrdered);

		return removedColumnUid;
	}

	function removeGroupRef(_removedGroup) {
		if (null === _removedGroup) return;

		let taskRefToDelete = ref(db, `/boards/${boardId}/groups/${_removedGroup}`);
		remove(taskRefToDelete)
			.then(function () {
				//delete Task from Database JSON
				//("delete complete");
			})
			.catch(function (error) {
				console.error("deleteGroup failed: " + error);
			});
	}

	function deleteColumn(_columnIndex) {
		const groupUids = Object.keys(RawTaskOrder);
		const groupId = groupUids[_columnIndex];

		//do we have any tasks yet?
		if (groupUids.length > 0) {
			removeGroupFromTaskOrder(groupId);
			deleteAllTasksReferencedInGroup(groupId);
		}

		const removedGroupUid = removeFromGroupOrder(_columnIndex);

		removeGroupRef(removedGroupUid);
	}

	function deleteAllTasksReferencedInGroup(_columnUid) {
		if (_columnUid === undefined || _columnUid === null) {
			console.error("deleteColumn: Invalid _columnIndex");
			return;
		}

		/* RawTaskOrder: 
		{ 
			"-MhjzotrioSFNErAgSlA":[0,"-MhjzqgqhBrifbAEYiH5","-MhjzqgqhBrifbAEYiH5"],
			"-MhjzpO8eXizRKDB0KDm":[0,"-Mhjzr0isR_OpIV9TMm1","-Mhjzr0isR_OpIV9TMm1"],
			"-MhjzpdSr5pU1S40MFOC":[0,"-MhjzrHCZojsH4udid5A","-MhjzrHCZojsH4udid5A"],
			"-MiHbw0C2IMhZ6YLA8SD":[0,"-MiHbwbC2g2f1H9e1_uS","-MiHbwhJnUmtIw4LWPkk","-MiHbzLZf3y4fANjA_u5","-MiHbzLZf3y4fANjA_u5"]}
		*/

		//[0,"-MhjzqgqhBrifbAEYiH5","-MhjzqgqhBrifbAEYiH5"]
		let colTaskOrder = Array.from(RawTaskOrder[_columnUid]);

		let removedTaskRefs = [];
		colTaskOrder.forEach((it) => {
			let taskRefToDelete = ref(db, `/boards/${boardId}/tasks/${it}`);
			removedTaskRefs.push(taskRefToDelete);
		});

		removedTaskRefs.forEach((iterator) => {
			let ref = iterator;
			remove(ref).catch(function (error) {
				console.error("Remove Task Failed: " + error.message);
			});
		});
	}

	function ReorderTasksImp(_targetColumnUid, _srcDragIndex, _destDragIndex) {
		if (!_targetColumnUid) {
			console.error(
				"useTopTen/ReorderTasksImp/Invalid _targetColumnUid: " +
					_targetColumnUid
			);
			return;
		}

		if (!RawTaskOrder.hasOwnProperty(_targetColumnUid)) {
			console.error("useTopTen/ReorderTasksImp/Column Key Error");
			return;
		}

		let targetColumnTaskArray = Array.from(RawTaskOrder[_targetColumnUid]);

		if (_srcDragIndex < 0 || _srcDragIndex >= targetColumnTaskArray.length) {
			console.error(
				"useTopTen/ReorderTasksImp/Invalid _srcDragIndex: " + _srcDragIndex
			);
			return;
		}

		if (_destDragIndex < 0 || _destDragIndex >= targetColumnTaskArray.length) {
			console.error(
				"useTopTen/ReorderTasksImp/Invalid _destDragIndex: " + _destDragIndex
			);
			return;
		}

		let resultArray = Reorder(
			targetColumnTaskArray,
			_srcDragIndex,
			_destDragIndex
		);

		RawTaskOrder[_targetColumnUid] = resultArray;

		UpdateLocalBoardState();

		//update remote state
		const newOrderRef = ref(
			db,
			`/boards/${boardId}/taskOrder/${_targetColumnUid}/`
		);

		SaveTaskOrder(newOrderRef, resultArray);
	}

	function MoveTaskImp(
		_srcColumnUid,
		_destColumnUid,
		_srcDragIndex,
		_destDragIndex
	) {
		if (!RawTaskOrder.hasOwnProperty(_srcColumnUid)) {
			console.error(
				"useTopTen/MoveTaskImp/Column Key Error: Source Column Invalid"
			);
			return;
		}

		let newSrcColumnTaskArray = Array.from(RawTaskOrder[_srcColumnUid]);
		const newDestColumnTaskArray = RawTaskOrder[_destColumnUid]
			? Array.from(RawTaskOrder[_destColumnUid])
			: [];

		Move(
			newSrcColumnTaskArray,
			newDestColumnTaskArray,
			_srcDragIndex,
			_destDragIndex
		);

		RawTaskOrder[_srcColumnUid] = newSrcColumnTaskArray;
		RawTaskOrder[_destColumnUid] = newDestColumnTaskArray;

		UpdateLocalBoardState();

		//save remote state
		var newOrderRef = ref(db, `/boards/${boardId}/taskOrder/${_srcColumnUid}/`);

		SaveTaskOrder(newOrderRef, newSrcColumnTaskArray);

		newOrderRef = ref(db, `/boards/${boardId}/taskOrder/${_destColumnUid}/`);

		SaveTaskOrder(newOrderRef, newDestColumnTaskArray);
	}

	function UpdateLocalBoardState() {
		const finalObject = {};
		finalObject.columnOrderArray = RawColumnOrder;

		finalObject.taskOrder = {};

		let columnKeys = Object.keys(RawTaskOrder);
		columnKeys.forEach((columnUid) => {
			finalObject.taskOrder[columnUid] = RawTaskOrder[columnUid];
		});

		finalObject.tasks = RawTaskData;
		finalObject.columnNames = RawGroupData;
		setFinalColumnTaskOrder(finalObject);
	}

	function ReorderColumnsImp(sourceIndex, destIndex) {
		if (sourceIndex < 0 || destIndex < 0) return;

		const columnKeysOrdered = RawColumnOrder ? Array.from(RawColumnOrder) : [];
		let [movedColUid] = columnKeysOrdered.splice(sourceIndex, 1);
		columnKeysOrdered.splice(destIndex, 0, movedColUid);

		//save local state
		SetRawColumnOrder(columnKeysOrdered);

		//UpdateLocalBoardState();
		//violates DRY but the SetRawColumnOrder above doesn't take effect immediately for some weird
		//javascript reason (event though setFinalColumnTaskOrder) does take effect immediately....
		const finalObject = {};
		finalObject.columnOrderArray = columnKeysOrdered;

		finalObject.taskOrder = {};

		let columnKeys = Object.keys(RawTaskOrder);
		columnKeys.forEach((columnUid) => {
			finalObject.taskOrder[columnUid] = RawTaskOrder[columnUid];
		});

		finalObject.tasks = RawTaskData;
		finalObject.columnNames = RawGroupData;
		setFinalColumnTaskOrder(finalObject);

		//save remote state
		const colunOrderDbRef = ref(db, `/boards/${boardId}/columnOrder/`);
		SaveColumnOrder(colunOrderDbRef, columnKeysOrdered);
	}
	return {
		ColumnData: finalColumnTaskOrder,
		AddMovie: addMovie,
		AddGroup: addGroup,
		RemoveTask: deleteTask,
		RemoveColumn: deleteColumn,
		ReorderColumns: ReorderColumnsImp,
		ReorderTasks: ReorderTasksImp,
		MoveTask: MoveTaskImp,
		UpdateGroupName: changeGroupName,
		UpdateTaskName: changeTaskName,
		UpdateTaskWatched: changeTaskWatched,
		UpdateBoardName: changeBoardName,
	};
};

export default useTopTen;
