import { useCallback, useReducer } from "react";

const TransactionStates = Object.freeze({
	Idle: "__IDLE__",
	Creating: "__CREATING__",
	Naming: "__NAMING__",
	Failure: "__FAILURE__",
});

const initState = {
	TransactionState: TransactionStates.Idle,
	data: -1,

	IsFocussed: function (_uniqueID) {
		const result =
			this.TransactionState === TransactionStates.Naming &&
			this.data === _uniqueID;

		return result;
	},
};

const ActionTypes = Object.freeze({
	CreateMovieBegin: "__CREATE_MOVIE_BEGIN__",
	CreateMovieSuccess: "__CREATE_MOVIE_SUCCESS__",
	NameMovieSuccess: "__NAME_MOVIE_SUCCESS__",
	RequestFailure: "__REQUEST_FAILURE__",
});

function reducer(state, action) {
	switch (action.type) {
		case ActionTypes.CreateMovieBegin:
			return {
				...state,
				TransactionState: TransactionStates.Creating,
			};

		case ActionTypes.CreateMovieSuccess:
			//do not handle naming if we have not started creating a movie
			if (state.TransactionState !== TransactionStates.Creating) return state;
			return {
				...state,
				TransactionState: TransactionStates.Naming,
				data: action.payload,
			};

		case ActionTypes.NameMovieSuccess:
		case ActionTypes.RequestFailure:
			return initState;

		default:
			console.warn("Invalid Dispatch Event Type: " + action.type);
			return state;
	}
}

const useAddMovie = () => {
	const [state, dispatch] = useReducer(reducer, initState);

	function startCreation(_title, _columnIndex) {
		// @ts-ignore
		dispatch({
			type: ActionTypes.CreateMovieBegin,
		});
	}

	const creationSuccessCallback = useCallback((_id) => {
		// (`useAddMovie/creationSuccessCallback/_id: ${_id}`);

		//@ts-ignore
		dispatch({
			type: ActionTypes.CreateMovieSuccess,
			payload: _id,
		});
	}, []);

	function changeName(_uniqueID, _newName) {
		// (`useAddMovie/changeName/_uniqueID: ${_uniqueID} _newName: ${_newName}`);
		//@ts-ignore
		dispatch({
			type: ActionTypes.NameMovieSuccess,
			payload: _newName,
		});
	}

	function OnFailure() {
		console.error(`useAddMovie/OnFailure`);

		//@ts-ignore
		dispatch({
			type: ActionTypes.RequestFailure,
		});
	}

	return {
		AddMovieTransaction: state,
		OnMovieCreation: creationSuccessCallback,
		StartTransaction: startCreation,
		ChangeName: changeName,
		OnFailure,
	};
};

export default useAddMovie;
