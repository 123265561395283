import { ref, remove, set } from "firebase/database";
import { getMessaging, onMessage } from "firebase/messaging";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SEGMENT_PATH_LANDING } from "../../constants/paths";
import useFollowers from "../../hooks/useFollowers";
import useFollowsList from "../../hooks/useFollowsList";
import useUserList from "../../hooks/useUserList";
import { db } from "../Firebase/fbConfig.js";
import { HomeIcon } from "../Icons/Icons.js";
import "./style.module.css";

function FollowsList({ user: _currentUser, unFollowUser: _unFollowCb }) {
	var _userData = useUserList() || {};
	var _followingData = useFollowsList(_currentUser?.uid) || {};

	function WriteFollowsElements(_followerUid, _index) {
		if (!_followerUid) return;

		const _followerUserData = _userData[_followerUid];

		function UnFollowButtonPress(event) {
			event.preventDefault();
			_unFollowCb(_followerUid);
		}

		return (
			<li key={_index} style={{ display: "flex" }}>
				<div style={{ display: "flex" }}>
					<p>
						<b>{_followerUserData?.email || "Data Error"}</b>
					</p>
					<button onClick={UnFollowButtonPress}>Unfollow</button>
				</div>
			</li>
		);
	}

	const followingArray = Object.keys(_followingData);

	return (
		<div>
			<h2>{_currentUser?.email} is following:</h2>
			<ul>{followingArray?.map(WriteFollowsElements)}</ul>
		</div>
	);
}

function FollowerList({ user: _currentUser }) {
	var _userData = useUserList() || {};
	var _followerData = useFollowers(_currentUser?.uid) || {};

	function WriteFollowerElements(_followerUid, _index) {
		if (!_followerUid) return;

		const _followerUserData = _userData[_followerUid];

		return (
			<li key={_index} style={{ display: "flex" }}>
				<div style={{ display: "flex" }}>
					<p>
						<b>{_followerUserData?.email || "Data Error"}</b>
					</p>
				</div>
			</li>
		);
	}

	const followerArray = Object.keys(_followerData);

	return (
		<div>
			<h2>{_currentUser?.email} followers:</h2>
			<ul>{followerArray?.map(WriteFollowerElements)}</ul>
		</div>
	);
}

function UserList({ user: _currentUser, toggleFollowUser: _followCb }) {
	var _userData = useUserList() || {};

	function WriteUserListElements(_entryUid, _index) {
		if (!_entryUid) return;

		const _entryUserData = _userData[_entryUid];

		function FollowButtonPress(event) {
			event.preventDefault();
			_followCb(_entryUid);
		}

		return (
			<li key={_index} style={{ display: "flex" }}>
				<div style={{ display: "flex" }}>
					<p>
						<b>{_entryUserData?.email || "Data Error"}</b>
					</p>
					<button onClick={FollowButtonPress}>Follow</button>
				</div>
			</li>
		);
	}

	const userListArray = Object.keys(_userData);

	const filteredUserListArray = userListArray.filter(function (ele) {
		return ele !== _currentUser.uid;
	});

	return (
		<div>
			<h2>User List</h2>
			<ul>{filteredUserListArray?.map(WriteUserListElements)}</ul>
		</div>
	);
}

function UserProfile({ CurrentUserData: _currentUser }) {
	let navigate = useNavigate();

	// Handle incoming messages. Called when:
	// - a message is received while the app has focus
	// - the user clicks on an app notification created by a service worker
	//   `messaging.onBackgroundMessage` handler.

	const messaging = getMessaging();
	onMessage(messaging, (payload) => {
		console.log("Message received. ", payload);

		console.log("Message Data Title: ", JSON.stringify(payload.data));

		// // Normally our Function sends a notification payload, we check just in case.
		// if (payload.notification) {
		// 	// If notifications are supported on this browser we display one.
		// 	// Note: This is for demo purposes only. For a good user experience it is not recommended to display browser
		// 	// notifications while the app is in focus. In a production app you probably want to only display some form of
		// 	// in-app notifications like the snackbar (see below).
		// 	if (window.Notification instanceof Function) {
		// 		// This displays a notification if notifications have been granted.
		// 		new Notification(payload.notification.title, payload.notification);
		// 	}
		// 	// Display the notification content in the Snackbar too.
		// 	//this.snackbar.MaterialSnackbar.showSnackbar({message: payload.notification.body});
		// }
	});

	function HomeButtonPress(event) {
		event.preventDefault();
		navigate(SEGMENT_PATH_LANDING);
	}

	function _toggleFollowUser(_targetUid) {
		const followUserRefRefString = `/follows/${_currentUser.uid}/${_targetUid}`;
		var followUserRef = ref(db, followUserRefRefString);
		set(followUserRef, true);

		const followerUserRefRefString = `/followers/${_targetUid}/${_currentUser.uid}`;
		var followerUserRef = ref(db, followerUserRefRefString);
		set(followerUserRef, true);
	}

	function _unFollowUser(_targetUid) {
		const followUserRefRefString = `/follows/${_currentUser.uid}/${_targetUid}`;
		var followUserRef = ref(db, followUserRefRefString);
		remove(followUserRef);

		const followerUserRefRefString = `/followers/${_targetUid}/${_currentUser.uid}`;
		var followerUserRef = ref(db, followerUserRefRefString);
		remove(followerUserRef);
	}

	return (
		<>
			<div onClick={HomeButtonPress}>
				<HomeIcon />
			</div>

			<h1>Welcome {_currentUser.email}</h1>
			<UserList user={_currentUser} toggleFollowUser={_toggleFollowUser} />
			<FollowsList user={_currentUser} unFollowUser={_unFollowUser} />
			<FollowerList user={_currentUser} />
		</>
	);
}

export default UserProfile;
