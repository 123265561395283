import { runTransaction } from "firebase/database";

function SaveColumnOrder(_columnOrderDbRef, _columnKeysOrdered) {
	if (!_columnKeysOrdered || !Array.isArray(_columnKeysOrdered)) {
		console.error(
			"UseTopTen: SaveColumnOrder: Invalid Firebase Database Column Order Array"
		);
		return;
	}

	if (!_columnOrderDbRef) {
		console.error(
			"UseTopTen: SaveColumnOrder: Invalid Firebase Database Column Reference"
		);
		return;
	}

	runTransaction(_columnOrderDbRef, (post) => {
		// let postData = _columnKeysOrdered.map((a) => a.uid);
		let postData = _columnKeysOrdered;

		//this return will set the data
		//returning undefined will abort the operation
		return postData;
	}).catch(function (error) {
		console.error("SaveColumnOrder/Synchronization failed");
	});
}

function SaveTaskOrder(orderRef, taskList) {
	if (!taskList) {
		console.error(
			"UseTopTen: SaveTaskOrder: Invalid Firebase Database Task List Data"
		);
		return;
	}

	if (!orderRef) {
		console.error(
			"UseTopTen: SaveTaskOrder: Invalid Firebase Database Column Reference"
		);
		return;
	}

	runTransaction(orderRef, (post) => {
		let postData = taskList;

		//this return will set the data
		//returning undefined will abort the operation
		return postData;
	}).catch(function (error) {
		console.error("SaveTaskOrder/Synchronization failed");
	});
}

export { SaveColumnOrder, SaveTaskOrder };
