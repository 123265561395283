import React from "react";
import { Link } from "react-router-dom";
import { enumInviteState } from "../../constants/inviteState";
import { SEGMENT_PATH_LANDING } from "../../constants/paths.js";
import { useAuth } from "../../context/AuthContext";
import Stepper from "./Stepper";

const defaultActions = [
	enumInviteState.loginCheck, //check our login and anonymously login
	enumInviteState.inviteCheck, //check the invite and link if possible
	enumInviteState.navHome, //go home if we suceeed
];

function Invite() {
	var {
		AuthAPI_LoginAnon: _funcLoginAnon,
		AuthAPI_CurrentUser: _currentUser,
		AuthAPI_LogOut: _funcLogOut,
	} = useAuth();

	return (
		<>
			<Link to={SEGMENT_PATH_LANDING}>Home</Link>
			<Stepper
				pActions={defaultActions}
				user={_currentUser}
				onLogin={_funcLoginAnon}
				onLogout={_funcLogOut}
				onCreateAccount={() => {
					return null;
				}}
			/>
		</>
	);
}

export default Invite;
