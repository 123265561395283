import { ref, set } from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../assets/delete_rounded_24px.svg";
import { SEGMENT_PATH_LANDING } from "../../constants/paths";
import { useAuth } from "../../context/AuthContext";
import useBoardList, { UserBoardStateEnum } from "../../hooks/useBoardList";
import BoardListAvatar from "../BoardListAvatar";
import { db } from "../Firebase/fbConfig.js";
import { LinkIcon, ShareIcon, UnLinkIcon } from "../Icons/Icons";
import styles from "./style.module.css";

const SETTING_DEFAULT_ADD_BOARD_NAME = "My New Board";
const SETTING_DEFAULT_FRIEND_NAME = "Arthur";
const SETTING_PLACEHOLDER_BOARD_NAME = SETTING_DEFAULT_FRIEND_NAME;
const SETTING_PLACEHOLDER_TASK = "The Little Mermaid";
const SETTING_PLACEHOLDER_GROUP_NAME =
	SETTING_DEFAULT_FRIEND_NAME + "'s Top 10";

let MODE_DEVELOP = false;

// The following should be shaken away by webpack
if (process.env.NODE_ENV === "development") {
	// eslint-disable-next-line
	MODE_DEVELOP = true;
}

function SuggestForm({
	UserID: _UserID,
	CreateBoard: _createBoard,
	AddMovie: _addMovie,
	AddGroup: _addGroup,
}) {
	const [boardNameInputText, setBoardNameInputText] = useState("");
	const inputTextBoardName = useRef(null);

	const [taskNameInputText, setTaskNameInputText] = useState("");
	const inputTextTask = useRef(null);

	if (!_UserID) {
		return <span>UserID: Invalid</span>;
	}

	if (!_createBoard) {
		return <span>CreateBoard: Invalid</span>;
	}

	function onAttemptAddSuggestion(event) {
		event.preventDefault();

		// if (!boardNameInputText || !_UserID || !taskNameInputText) return;
		if (!_UserID) return;

		let boardName = boardNameInputText;
		let taskName = taskNameInputText;
		let groupName = SETTING_PLACEHOLDER_GROUP_NAME;

		//override with placeholders if the user didn't input anything
		if (!boardName || boardName.length < 1)
			boardName = SETTING_PLACEHOLDER_BOARD_NAME;

		if (!taskName || taskName.length < 1) taskName = SETTING_PLACEHOLDER_TASK;

		_createBoard(_UserID, boardName)
			.then(function (newBoardKey) {
				//("Wrote Board Successfully..." + newBoardKey);

				_addGroup(groupName, newBoardKey)
					.then(function (newGroupKey) {
						//("Wrote Group Successfully..." + newGroupKey);
						_addMovie(taskName, newBoardKey, newGroupKey)
							.then(function (newMovieKey) {
								//("Wrote Movie Successfully..." + newMovieKey);
							})
							.catch(function (error) {
								console.error("Add Movie Write Error: ", error);
							});
					})
					.catch(function (error) {
						console.error("Add Group Write Error: ", error);
					});
			})
			.catch(function (error) {
				console.error("Board Write Error: ", error);
			});

		setBoardNameInputText("");
		setTaskNameInputText("");
		inputTextBoardName.current.value = "";
		inputTextTask.current.value = "";
	}

	// const isInvalid = boardNameInputText === "" || taskNameInputText === "";

	return (
		<form>
			<div className={styles.SuggestionContainer}>
				<p className={styles.SuggestionElement}> I want to recommend </p>

				<input
					className={styles.SuggestionElement}
					ref={inputTextTask}
					name="InputText_TaskName"
					onChange={(event) => {
						setTaskNameInputText(event.target.value);
					}}
					type="text"
					placeholder={SETTING_PLACEHOLDER_TASK}
				/>
				<p className={styles.SuggestionElement}> to </p>
				<input
					className={styles.SuggestionElement}
					ref={inputTextBoardName}
					name="InputText_BoardName"
					onChange={(event) => {
						setBoardNameInputText(event.target.value);
					}}
					type="text"
					placeholder={SETTING_PLACEHOLDER_BOARD_NAME}
				/>
				<button
					className={styles.SuggestionElement}
					onClick={onAttemptAddSuggestion}
				>
					Add Board
				</button>
			</div>
		</form>
	);
}

function AddBoardForm({ UserID: _UserID, CreateBoard: _createBoard }) {
	const [boardNameInputText, setBoardNameInputText] = useState("");
	const inputEl = useRef(null);

	if (!_UserID) {
		return <span>UserID: Invalid</span>;
	}

	if (!_createBoard) {
		return <span>CreateBoard: Invalid</span>;
	}

	function onAttemptAddBoard(event) {
		event.preventDefault();

		if (boardNameInputText.length <= 1) {
			inputEl?.current?.focus();
			return;
		}

		if (!boardNameInputText || !_UserID) return;

		_createBoard(_UserID, boardNameInputText)
			.then(function () {
				//("onAttemptAddBoard/Wrote Board Successfully...");
			})
			.catch(function (error) {
				console.error("onAttemptAddBoard/Board Write Error: ", error);
			});

		setBoardNameInputText("");
		inputEl.current.value = "";
		inputEl?.current?.blur();
	}

	return (
		<form>
			<input
				ref={inputEl}
				name="InputText_BoardName"
				onChange={(event) => {
					setBoardNameInputText(event.target.value);
				}}
				type="text"
				placeholder={SETTING_DEFAULT_ADD_BOARD_NAME}
			/>

			<button onClick={onAttemptAddBoard}>Add Board</button>
		</form>
	);
}

function CardButton({ onClick, children }) {
	return (
		<div onClick={onClick} className={`${styles.CardButton} ${styles.ripple}`}>
			{children}
		</div>
	);
}

function CardActions({ children }) {
	return <div className={styles.BoardCard_Actions}>{children}</div>;
}

function BoardListCard({ children }) {
	return <div className={styles.BoardCard}>{children}</div>;
}

function UserBoards({
	UserID: _userId,
	UserBoards: _boardData,
	RemoveBoard: _removeBoard,
	ShareBoard: _shareBoard,
	ChangeAvatar: _changeAvatar,
}) {
	let navigate = useNavigate();
	if (null === _boardData) {
		return <span>No Boards Yet...</span>;
	}

	const keys = Object.keys(_boardData);
	if (keys.length <= 0) {
		return <span>Please create a board!...</span>;
	}

	return (
		<div className={styles.boardContent}>
			<div className={styles.boardContainer}>
				{keys.map((key) => (
					<BoardListCard key={key}>
						<BoardListAvatar
							onNavClick={(event) => {
								event.preventDefault();
								navigate(`/board/${key}`);
							}}
							onFaceClick={(event) => {
								event.preventDefault();
								_changeAvatar(_userId, key).catch(function (err) {
									console.error("avatar error", err);
								});
							}}
							seed={_boardData[key]?.avatarSeed || "anything"}
						/>
						<div className={styles.UsernameHeader}>
							{_boardData[key]?.title || "A User Board"}
						</div>
						<CardActions>
							<ShareUI
								user={_userId}
								boardId={key}
								data={_boardData[key]}
								onShare={_shareBoard}
							/>

							<CardButton
								onClick={(event) => {
									event.preventDefault();
									navigate(`/board/${key}`);
								}}
							>
								View
							</CardButton>
							<TrashIcon
								className={styles.SvgIcon}
								onClick={(event) => {
									event.preventDefault();
									_removeBoard(_userId, key).catch(function (err) {
										console.error("Remove Board.", err);
									});
								}}
							/>
						</CardActions>
					</BoardListCard>
				))}
			</div>
		</div>
	);
}

function ShareUI({ user, boardId, data, onShare }) {
	function renderSwitch(_state) {
		switch (_state) {
			case UserBoardStateEnum.normal:
				return (
					<div
						className={styles.TrashIcon}
						onClick={function (event) {
							event.preventDefault();
							onShare(boardId, user);
						}}
					>
						<ShareIcon />
					</div>
				);
			case UserBoardStateEnum.linked:
				return <LinkIcon />;
			case UserBoardStateEnum.invited:
				return <UnLinkIcon />;
			default:
				return null;
		}
	}

	return (
		<div className={styles.ShareBlock}>{renderSwitch(data?.linkState)}</div>
	);
}

function requestPermission() {
	if (!("Notification" in window)) {
		console.error("This browser does not support desktop notification");
		return;
	}

	Notification.requestPermission()
		.then(function () {
			saveToken();
		})
		.catch(function (err) {
			console.error("Unable to get permission to notify.", err);
		});
}

const enableNotifications = false;

function saveToken(user) {
	//get token requests permission if this browser/user combination doesn't have it
	//if it doesn't then we request it manually and try again
	if (!enableNotifications) {
		return null;
	}

	if (!("serviceWorker" in navigator)) {
		console.warn(
			"Top Ten: Warning - This browser does not support notifications",
			user
		);
		return null;
	}

	if (!user) {
		console.warn("saveToken/ invalid user: ", user);
		return;
	}

	const messaging = getMessaging();
	getToken(messaging, {
		//serviceWorkerRegistration: registration,
		vapidKey:
			"BKApZGlCO-GDLnjoZ8Vxc25xJnjsUjvestj9JEkJFyb_i0U7rWss57MiTxEvT1am1EAFGY8tp69BLo0s9Vs7WBk",
	})
		.then(function (currentToken) {
			if (currentToken) {
				set(
					ref(db, "users/" + user.uid + "/notificationTokens/" + currentToken),
					true
				);
			} else {
				requestPermission();
			}
		})
		.catch(function (err) {
			console.error("saveToken/ Unable to get messaging token.", err);
			if (err.code === "messaging/permission-default") {
				console.error(
					"You have not enabled notifications on this browser. To enable notifications reload the page and allow notifications using the permission dialog."
				);
			} else if (
				err.code === "messaging/notifications-blocked" ||
				err.code === "messaging/permission-blocked"
			) {
				console.error(
					'You have blocked notifications on this browser. To enable notifications follow these instructions: <a href="https://support.google.com/chrome/answer/114662?visit_id=1-636150657126357237-2267048771&rd=1&co=GENIE.Platform%3DAndroid&oco=1">Android Chrome Instructions</a> <a href="https://support.google.com/chrome/answer/6148059">Desktop Chrome Instructions</a>"'
				);
			}
		});
}

function BoardList() {
	var { AuthAPI_CurrentUser: _currentUser } = useAuth();

	var _userId = _currentUser?.uid;

	useEffect(() => {
		saveToken(_currentUser);
	}, [_currentUser]);

	const {
		BoardData: _userBoards,
		DataState: _boardDataState,
		RemoveBoard: _removeBoard,
		CreateBoard: _createBoard,
		ShareBoard: _shareBoard,
		AddMovie: _addMovie,
		AddGroup: _addGroup,
		ChangeAvatar: _changeAvatar,
	} = useBoardList(_userId);

	return (
		<div className={styles.PagePadding}>
			<AuthHeader />

			{_boardDataState.IsIdle() ? (
				<>
					{null === _userBoards ? (
						<SuggestForm
							UserID={_userId}
							CreateBoard={_createBoard}
							AddMovie={_addMovie}
							AddGroup={_addGroup}
						/>
					) : (
						UserBoardList(
							_userId,
							_createBoard,
							_userBoards,
							_removeBoard,
							_shareBoard,
							_changeAvatar
						)
					)}
				</>
			) : (
				<div className={styles.spinner} />
			)}
		</div>
	);
}

function UserBoardList(
	_userId,
	_createBoard,
	_userBoards,
	_removeBoard,
	_shareBoard,
	_changeAvatar
) {
	return (
		<>
			<AddBoardForm UserID={_userId} CreateBoard={_createBoard} />
			<UserBoards
				UserID={_userId}
				UserBoards={_userBoards}
				RemoveBoard={_removeBoard}
				ShareBoard={_shareBoard}
				ChangeAvatar={_changeAvatar}
			/>
		</>
	);
}

const debugNav = false;
function AuthHeader() {
	var authAPI = useAuth();

	//define {user, funcLogOut } with weird js object destructuring bullshit
	var { AuthAPI_CurrentUser: _currentUser, AuthAPI_LogOut: funcLogOut } =
		authAPI;

	let navigate = useNavigate();

	if (_currentUser === false || _currentUser === null) {
		// return <SignIn loginWithGoogle={null} signInAnon={null} />;
		return <p>You are not logged in. User False</p>;
	}

	const userName = _currentUser?.email || "Stranger";

	return (
		<div className={styles.header}>
			<h3 className={styles.headerText}>
				Welcome{" "}
				{debugNav ? (
					<>
						<Link to={`/user/`}>{userName}</Link>!
					</>
				) : (
					<>{userName}</>
				)}
			</h3>

			<button
				onClick={() => {
					funcLogOut().then(() => {
						navigate(SEGMENT_PATH_LANDING);
					});
				}}
			>
				Sign out
			</button>
		</div>
	);
}

export default BoardList;
